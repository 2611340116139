import React, { Component } from 'react';
import {Form, Breadcrumb, Button, Image, FormGroup} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        note : '',
        original_name : '',
        upload_file : '',
    }
  }


  handleUpload= event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan diupload?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Upload', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        return fetch(global.variable.LINK_API + 'uploadbuktiupp/store',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Sukses Upload', "success")
          .then((value) => {
            window.location.href="/uploadbuktiuppdetail";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

    }
  
  };

  getFiles(files){
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
      }
      else{
        this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
    }
  }

  onChangeHandler=event=>{
    var file = event.target.files[0];

    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }else if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
      console.log(file);
       this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0], original_name : '', upload_file : ''});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }

     
      
    })

    fetch(global.variable.LINK_API + 'uniform/' + localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
     
      //console.log(resData);
      console.log(this.state.uniform);
      console.log(localStorage.getItem('uniform1'));
       
    })

    
  }
  fileUploadHandler = event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)
        return fetch(global.variable.LINK_API + 'uniform/upload',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
          .then((value) => {
            window.location.href="/uploadbuktiseragam";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    }
 
  };
  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open(param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }
  
  formupload(){
    let guardian = this.state.upload_upp;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Bukti {this.state.upload_upp[i].counter}</Form.Label>
       
        <div className='rowHorizontal'>
        {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}
        
        <Link  target="_blank" onClick={this.download(this.state.upload_upp[i].id)}>Lihat Bukti</Link>

        {this.state.upload_upp[i].nominal !==0?
        <div>
          <Form.Label style={{marginLeft:20}}><CurrencyFormat  displayType={'text'} prefix ={'Rp.'} thousandSeparator={true} value={this.state.upload_upp[i].nominal}/></Form.Label>
        </div>
        :''}
        </div>
      </Form.Group>
      )
    }

    return showitem;
  }


  Buttonsub(){
    let guardian = this.state.upload_upp;
    let showitem = [];
    //console.log(guardian);
    let show = false;

    for (let i = 0; i < guardian.length; i++) { 
      
      if (this.state.upload_upp[i] && this.state.upload_upp[i].verify_by ===""){
        show =true}
      

    }
    showitem.push(
      <Form.Group controlId="exampleForm.ControlSelect1">
      {show?
      <div>
        <div className='rowHorizontal'>
       <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
        <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
      </div>
      </div>
      :''}
      
    </Form.Group>
    )

    return showitem;
  }



  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleConfirm= event =>{
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0]}

    fetch(global.variable.LINK_API + 'uniform/confirm',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Konfirmasi Bukti Bayar Seragam', "success")
        .then((value) => {
          window.location.href="/verifikasiseragam";
        });
      }
       
    })
  }

  handleTolak= event =>{

    global.swal({
      text: 'Alasan Penolakan',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0],  note_reject_uniform : confirm}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'uniform/reject',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/verifikasiseragam";
        });
      }
       
    })
    .catch(err => {
      console.log(err)
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });
  }

  formbukti(){
      return(
        <div>
        <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label>Catatan</Form.Label>
        <Form.Control 
          type="Text"
          name="note"
          maxLength="50"
          value={this.state.upload_uniform.note}
          onChange={this.onChange}  disabled/>
      </Form.Group>
         <Link  target="_blank" onClick={this.download(this.state.upload_uniform.link)}>Lihat Bukti</Link>
         </div>
      )
  }

  handleBack = () => {
    window.location.href="/uploadbukti";
  }

  showUniform() {
    
   
    let showitem = [];
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
    totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    
    showitem.push(
      
      <Form.Group controlId="exampleForm.ControlInput1">
      <Form.Group controlId="exampleForm.ControlInput1">
      <hr style={{Color:'black'}}></hr>
      <Form.Label>Seragam</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].uniform_name}
        disabled />
    </Form.Group>

    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Ukuran</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].size}
        disabled />
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Harga</Form.Label>
      <Form>
        <CurrencyFormat 
        value={this.state.uniform_data[i].price} 
        style={{ width : '100%'}} 
        thousandSeparator={true} 
        prefix={'Rp. '} disabled />
      </Form>
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Jumlah</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].qty}
        disabled />
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      <Form.Label>Total Harga</Form.Label>
      <Form>
        <CurrencyFormat 
        value={this.state.uniform_data[i].qty * this.state.uniform_data[i].price} 
        // value ={this.state.totalharga}
        style={{ width : '100%'}} 
        thousandSeparator={true} 
        prefix={'Rp. '} disabled />
      </Form>
    </Form.Group>
    <Form.Group controlId="exampleForm.COntrolInput1">
      {this.state.uniform_data[i].name?
      <div>
      <Form.Label>Nama</Form.Label>
      <Form.Control 
        type="Text"
        name="jalur"
        value={this.state.uniform_data[i].name}
        disabled />
        </div>
        :''}
    </Form.Group>
    </Form.Group>
        )
      }
      showitem.push(
        <Form.Group controlId="exampleForm.COntrolInput1">
          <Form.Label>Total Harga Keseluruhan</Form.Label>
          <Form>
            <CurrencyFormat 
            value ={totalnominal}
            style={{ width : '100%'}} 
            thousandSeparator={true} 
            prefix={'Rp. '} disabled />
          </Form>
        </Form.Group>
      )
      

      return showitem;
    
  }

  Total(){
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }


  render() {
    let counter = 0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Verifikasi Bukti Seragam</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.fileUploadHandler}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Group>
                          {this.state.uniform_data && this.state.uniform_data.length? 
                          
                          // this.showUniform()
                          <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:100, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="50" style={{textAlign: "center", fontSize:12}}>No. </th>
                        <th width="150" style={{textAlign: "center", fontSize:12}}>Nama Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Harga Satuan</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Ukuran</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.is_showdata?
                       
                       
                        this.state.uniform.map((item, idx) => {
                        let showitem = [];
                        let price = 0;
                        let qty = 0;
                        let size ='-';
                        let total = 0;
                        
                        counter++;
                        //console.log(this.state.uniform_data.length)

                        for (let i = 0; i < this.state.uniform_data.length; i++) {
                           
                        // this.state.uniform_data.map((item2, idx2) => {
                            
                            //  console.log(this.state.uniform_data[i].price)
                            if(this.state.uniform_data[i].uniform_id == item.id){
                                price = this.state.uniform_data[i].price;
                                qty = this.state.uniform_data[i].qty;
                                size = this.state.uniform_data[i].size;
                                total = this.state.uniform_data[i].qty*this.state.uniform_data[i].price;

                               
                            }
                            else{
                                // console.log("tidak masuk if")

                            }
                           

                           
                        }
                        return (
                            <tr key={item.id}>
                                <td style={{textAlign: "center"}}>{counter}</td>
                                <td>{item.name}</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={price}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                                <td style={{textAlign: "center"}}>{qty}</td>
                                <td style={{textAlign: "center"}}>{size}</td>  
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={total}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                            </tr>
                            
                        )
                      
                       
                        
                        
                        })
                    :''}
                      </tbody>
                     
                            <tr>
                                
                                <td colSpan="5" style={{textAlign: "center", fontSize:12}}>Total</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.Total()}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                    </td>
                            </tr>
                      
                   
                    </table>
                  </div>
            </div>



                           : ''}
                          </Form.Group>
                          
                          {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Bukti Bayar</Form.Label>
                          </div>
                          : ''}
                          <FormGroup>
                          {this.state.is_show ? 
                          this.formbukti()
                           : ''}
                           </FormGroup>


                          
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleTolak} block style={{margin:5, height:50}}>Tolak</Button>
                            <Button onClick={this.handleConfirm} block style={{margin:5, height:50}} >Confirm Pemesanan Seragam</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;