import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import {  DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import CurrencyFormat from 'react-currency-format';
import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        guardian_id1 : '',
        fullname1 : '',
        nik1 : '',
        dob1 : '1980-01-01',
        status_guardian_id1 : '',
        parent_status_id :'',
        both_parent_work:'',
        education_id1 : '',
        occupation_id1 : '',
        salary1 : '',
        hp1 : '',
        address1 : '',
        guardian_id1_name : '',
        status_guardian_id1_name : '',
        education_id1_name : '',
        occupation_id1_name : '',
        religion_guardian_id1 : '',
        religion_guardian_id1_name : '',
        guardian_id2 : '',
        fullname2 : '',
        nik2 : '',
        dob2 : '1980-01-01',
        status_guardian_id2 : '',
        education_id2 : '',
        occupation_id2 : '',
        salary2 : '',
        hp2 : '',
        address2 : '',
        guardian_id2_name : '',
        status_guardian_id2_name : '',
        education_id2_name : '',
        occupation_id2_name : '',
        religion_guardian_id2 : '',
        religion_guardian_id2_name : '',
        guardian_id3 : '',
        fullname3 : '',
        nik3 : '',
        dob3 : '1980-01-01',
        status_guardian_id3 : '1',
        education_id3 : '1',
        occupation_id3 : '1',
        salary3 : '',
        hp3 : '',
        address3 : '',
        guardian_id3_name : '',
        status_guardian_id3_name : '',
        education_id3_name : '',
        occupation_id3_name : '',
        religion_guardian_id3 : '',
        religion_guardian_id3_name : '',
    }
  }

  validateForm() {
    return this.state.fullname2.length > 0 ?
    this.state.fullname2.length > 0
    && this.state.parent_status_id.length >0
    && this.state.both_parent_work.length >0
    && this.state.nik2.length > 0
    && this.state.dob2.length > 0
    && this.state.education_id2.length > 0
    && this.state.occupation_id2.length > 0
    && this.state.address2.length > 0
    && this.state.religion_guardian_id2.length > 0
    :1
    &&
    this.state.fullname1.length > 0 ?
    this.state.fullname1.length > 0
    && this.state.nik1.length > 0
    && this.state.dob1.length > 0
    && this.state.education_id1.length > 0
    && this.state.occupation_id1.length > 0
    && this.state.address1.length > 0
    && this.state.religion_guardian_id1.length > 0
    :1
    ;
  }

  
  onChangeNom = e => this.setState({ [e.target.name]: remSep(e.target.value) })

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));

    let item =[]; 
    let items =  Object.create({});
    items.guardian_id = this.state.guardian_id1;
    items.fullname = this.state.fullname1;
    items.nik = this.state.nik1;
    items.dob = this.state.dob1;
    items.status_guardian_id = this.state.status_guardian_id1;
    items.education_id = this.state.education_id1;
    items.occupation_id = this.state.occupation_id1;
    items.salary = this.state.salary1?this.state.salary1:0;
    items.hp = this.state.hp1;
    items.address = this.state.address1;
    items.religion_guardian_id = this.state.religion_guardian_id1;
    item.push(items);

    let items2 =  Object.create({});
    items2.guardian_id = this.state.guardian_id2;
    items2.fullname = this.state.fullname2;
    items2.nik = this.state.nik2;
    items2.dob = this.state.dob2;
    items2.status_guardian_id = this.state.status_guardian_id2;
    items2.education_id = this.state.education_id2;
    items2.occupation_id = this.state.occupation_id2;
    items2.salary = this.state.salary2?this.state.salary2:0;
    items2.hp = this.state.hp2;
    items2.address = this.state.address2;
    items2.religion_guardian_id = this.state.religion_guardian_id2;
    item.push(items2);

    let items3 =  Object.create({});
    items3.guardian_id = this.state.guardian_id3;
    items3.fullname = this.state.fullname3;
    items3.nik = this.state.nik3;
    items3.dob = this.state.dob3;
    items3.status_guardian_id = this.state.status_guardian_id3;
    items3.education_id = this.state.education_id3;
    items3.occupation_id = this.state.occupation_id3;
    items3.salary = this.state.salary3;
    items3.hp = this.state.hp3;
    items3.address = this.state.address3;
    items3.religion_guardian_id = this.state.religion_guardian_id3;
    item.push(items3);

    
      localStorage.setItem('reg4', JSON.stringify(item));
      localStorage.setItem('statereg4', JSON.stringify(this.state));
      
      console.log("score " + JSON.stringify(item));
      console.log(JSON.stringify(this.state))
      window.location.href="/pendaftaran4";


  }

  componentDidMount() {
    if(localStorage.getItem('statereg4')){
      this.setState(JSON.parse(localStorage.getItem('statereg4')));
    }
    
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmGuardian;
    this.setState({guardian_id1 : params[0].id, guardian_id2 : params[1].id, guardian_id3 : params[2].id, guardian_id_name1 : params[0].name, guardian_id_name2 : params[1].name, guardian_id_name3 : params[2].name});


    console.log(localStorage.getItem('reg4'))
  }

  createSelectGuardian() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmGuardian;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectStatus() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmStatusGuardian;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectParentStatus() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmParentStatus;

    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;

  }

  createSelectWorkingParents() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmWorkingParents;

    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;

  }

  createSelectPendidikan() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmEducation;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectPekerjaan() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmOccupation;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectReligion() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmReligion;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  showRemove(){
    if(localStorage.getItem('reg4')){
      return(
        <Button onClick={this.handleClear} block style={{margin:5, height:30, width: 100}}>Clear Data</Button>
      );
    }
  }

  showGuardian() {
    if(localStorage.getItem('reg4')){
      let guardian = JSON.parse(localStorage.getItem('reg4'));
      console.log(localStorage.getItem('reg4'));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) { 
        console.log(guardian[i].nik);
        showitem.push(
          <div key={guardian[i].nik}>
            <hr/>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Orang Tua </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].guardian_id_name} </Form.Label>
              
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Fullname </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].fullname} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>NIK </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].nik} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Tanggal Lahir </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].dob} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Agama </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].religion_guardian_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Status </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].status_guardian_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Pendidikan </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].education_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Pekerjaan </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].occupation_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Penghasilan </Form.Label>
              <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].salary} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>No HP </Form.Label>
              <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].hp} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Alamat </Form.Label>
              <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].address} </Form.Label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  /*createSelectWorkingParents() {
    // ini valuenya
    let items = [];          
         items.push(
         <option value="1">YA</option>,
         <option value="0">TIDAK</option>); 
         
    return items;
    
  }*/

  

  /*onDropdownSelected2(e) {
    console.log("THE VAL", e.target.value);    
    this.setState({both_parent_work: e.target.value});
  }*/
  onDropdownSelected2(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value.split('_')[0], [e.target.name + "_name"]: e.target.value.split('_')[1]});
  }

  onDropdownSelected3(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onChangeDate1 = (jsDate, dateString) => {
    this.setState({dob1: dateString});
  }

  onChangeDate2 = (jsDate, dateString) => {
    this.setState({dob2: dateString});
  }

  onChangeDate3 = (jsDate, dateString) => {
    this.setState({dob3: dateString});
  }

  handleSubmit = event => {
    event.preventDefault();    
    
    let item =[]; 
    let data = Object.create({});
    // data.parent_status_id = this.state.parent_status_id;
    // data.both_parent_work = this.state.both_parent_work;
    // if(this.state.parent_status_id || this.state.both_parent_work)
    // {
    //   item.push(data)
    // }


    let items =  Object.create({});
    items.guardian_id = this.state.guardian_id1;
    items.fullname = this.state.fullname1;
    items.nik = this.state.nik1;
    items.dob = this.state.dob1;
    items.status_guardian_id = this.state.status_guardian_id1;
    items.education_id = this.state.education_id1;
    items.occupation_id = this.state.occupation_id1;
    items.salary = this.state.salary1?this.state.salary1:0;
    items.hp = this.state.hp1;
    items.address = this.state.address1;
    items.religion_guardian_id = this.state.religion_guardian_id1;
    if(this.state.fullname1){item.push(items);}

    let items2 =  Object.create({});
    items2.guardian_id = this.state.guardian_id2;
    items2.fullname = this.state.fullname2;
    items2.nik = this.state.nik2;
    items2.dob = this.state.dob2;
    items2.status_guardian_id = this.state.status_guardian_id2;
    items2.education_id = this.state.education_id2;
    items2.occupation_id = this.state.occupation_id2;
    items2.salary = this.state.salary2?this.state.salary2:0;
    items2.hp = this.state.hp2;
    items2.address = this.state.address2;
    items2.religion_guardian_id = this.state.religion_guardian_id2;
    if(this.state.fullname2){item.push(items2);}

    let items3 =  Object.create({});
    items3.guardian_id = this.state.guardian_id3;
    items3.fullname = this.state.fullname3;
    items3.nik = this.state.nik3;
    items3.dob = this.state.dob3;
    items3.status_guardian_id = this.state.status_guardian_id;
    items3.education_id = this.state.education_id3;
    items3.occupation_id = this.state.occupation_id3;
    items3.salary = this.state.salary3?this.state.salary3:0;
    items3.hp = this.state.hp3;
    items3.address = this.state.address3;
    items3.religion_guardian_id = this.state.religion_guardian_id3;
    if(this.state.fullname3){item.push(items3);}
    
    // if(this.state.both_parent_work===""){
    //   global.swal("Info", "Harap pilih Keadaan Orang Tua Bekerja", "info");
    // }else if(this.state.parent_status_id===""){
    //   global.swal("Info", "Harap pilih Keadaan Orang Tua", "info");
    // }
    // else{

    
      localStorage.setItem('reg4', JSON.stringify(item));
      localStorage.setItem('statereg4', JSON.stringify(this.state));
      console.log("score " + JSON.stringify(item));
      console.log(JSON.stringify(this.state))
      window.location.href="/pendaftaran5";
    // }
  }

  

  handleClear = () =>{
    localStorage.removeItem('reg4');
    window.location.href="/pendaftaran4";
  }

  handleBack = () => {
    window.location.href="/pendaftaran3";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 4</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>

                        <Form.Label>Keadaan Orang Tua *</Form.Label>
                            <Form.Control as="select" name="parent_status_id" onChange={this.onDropdownSelected3.bind(this)} value={this.state.parent_status_id} required>
                              <option value="">-Pilih Status-</option>
                              {console.log(this.state.parent_status_id)}
                              {this.createSelectParentStatus()}
                            </Form.Control>

                        <Form.Label>Keadaan Pekerjaan Orang Tua *</Form.Label>
                            <Form.Control as="select" name="both_parent_work" onChange={this.onDropdownSelected2.bind(this)} value={this.state.both_parent_work} required>
                              <option value="">-Pilih-</option>
                              {console.log(this.state.both_parent_work)}
                              {this.createSelectWorkingParents()}
                            </Form.Control>

                          {/*<Form.Group controlId="exampleForm.ControlSelect1">
                              <Form.Label>Keadaan Pekerjaan Orang Tua *</Form.Label>
                              <Form.Control as="select" onChange={this.onDropdownSelected2.bind(this)} value={this.state.both_parent_work} required>
                                <option value="">-Pilih Keadaan Orang Tua Bekerja-</option>
                                {this.createSelectWorkingParents()}
                              </Form.Control>
                          </Form.Group>*/}

                          {/* <Form onSubmit={this.handleSubmit}> */}
                          {this.state.parent_status_id === '1' ?
                          <div>

                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data {this.state.guardian_id_name1}</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname1"
                              maxLength="200"
                              value={this.state.fullname1}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik1" 
                              onChange={this.onChange} 
                              value={this.state.nik1}
                              maxLength="16"
                              style={{ width : '100%'}} 
                              required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir *</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob1}
                              value={this.state.dob1}
                              onChange={this.onChangeDate1}
                              readOnly={true}
                              style={{width:200}}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_guardian_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_guardian_id1 + '_' + this.state.religion_guardian_id1_name} required>
                              <option value="">-Pilih Agama-</option>
                              {console.log(this.state.religion_guardian_id1)}
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" name="status_guardian_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.status_guardian_id1 + '_' + this.state.status_guardian_id1_name} required>
                              <option value="">-Pilih Status-</option>
                              {console.log(this.state.status_guardian_id1)}
                              {this.createSelectStatus()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pendidikan Terakhir *</Form.Label>
                            <Form.Control as="select" name="education_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.education_id1 + '_' + this.state.education_id1_name} required>
                              <option value="">-Pilih Pendidikan-</option>
                              {this.createSelectPendidikan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pekerjaan *</Form.Label>
                            <Form.Control as="select" name="occupation_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.occupation_id1 + '_' + this.state.occupation_id1_name} required>
                              <option value="">-Pilih Pekerjaan-</option>
                              {this.createSelectPekerjaan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penghasilan (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              name="salary1" 
                              onChange={this.onChangeNom} 
                              value={this.state.salary1}
                              maxLength="50"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No HP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="hp1" 
                              onChange={this.onChange} 
                              value={this.state.hp1}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address1"
                              maxLength="200"
                              value={this.state.address1}
                              onChange={this.onChange} required/>
                          </Form.Group>

                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data {this.state.guardian_id_name2}</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname2"
                              maxLength="200"
                              value={this.state.fullname2}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik2" 
                              onChange={this.onChange} 
                              value={this.state.nik2}
                              maxLength="16"
                              style={{ width : '100%'}} 
                              required />
                            </Form>
                             
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir*</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob2}
                              value={this.state.dob2}
                              onChange={this.onChangeDate2}
                              readOnly={true}
                              style={{width:200}}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_guardian_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_guardian_id2 + '_' + this.state.religion_guardian_id2_name} required>
                              <option value="">-Pilih Agama-</option>
                              {console.log(this.state.religion_guardian_id2)}
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" name="status_guardian_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.status_guardian_id2 + '_' + this.state.status_guardian_id2_name} required>
                              <option value="">-Pilih Status-</option>
                              {this.createSelectStatus()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pendidikan Terakhir *</Form.Label>
                            <Form.Control as="select" name="education_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.education_id2 + '_' + this.state.education_id2_name} required>
                              <option value="">-Pilih Pendidikan-</option>
                              {this.createSelectPendidikan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pekerjaan *</Form.Label>
                            <Form.Control as="select" name="occupation_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.occupation_id2 + '_' + this.state.occupation_id2_name} required>
                              <option value="">-Pilih Pekerjaan-</option>
                              {this.createSelectPekerjaan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penghasilan (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              name="salary2" 
                              onChange={this.onChangeNom} 
                              value={this.state.salary2}
                              maxLength="50"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No HP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="hp2" 
                              onChange={this.onChange} 
                              value={this.state.hp2}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address2"
                              maxLength="200"
                              value={this.state.address2}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group>
                          {/*this.showRemove()}
                          {this.showGuardian()*/}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}}  >Next</Button>
                          </div>
                          </Form.Group>
                          </div>
                          :""
                        }

                        {this.state.parent_status_id === '2' ?
                        <div>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data {this.state.guardian_id_name1}</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname1"
                              maxLength="200"
                              value={this.state.fullname1}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik1" 
                              onChange={this.onChange} 
                              value={this.state.nik1}
                              maxLength="16"
                              style={{ width : '100%'}} 
                              required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir *</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob1}
                              value={this.state.dob1}
                              onChange={this.onChangeDate1}
                              readOnly={true}
                              style={{width:200}}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_guardian_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_guardian_id1 + '_' + this.state.religion_guardian_id1_name} required>
                              <option value="">-Pilih Agama-</option>
                              {console.log(this.state.religion_guardian_id1)}
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" name="status_guardian_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.status_guardian_id1 + '_' + this.state.status_guardian_id1_name} required>
                              <option value="">-Pilih Status-</option>
                              {this.createSelectStatus()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pendidikan Terakhir *</Form.Label>
                            <Form.Control as="select" name="education_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.education_id1 + '_' + this.state.education_id1_name} required>
                              <option value="">-Pilih Pendidikan-</option>
                              {this.createSelectPendidikan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pekerjaan *</Form.Label>
                            <Form.Control as="select" name="occupation_id1" onChange={this.onDropdownSelected.bind(this)} value={this.state.occupation_id1 + '_' + this.state.occupation_id1_name} required>
                              <option value="">-Pilih Pekerjaan-</option>
                              {this.createSelectPekerjaan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penghasilan (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              name="salary1" 
                              onChange={this.onChangeNom} 
                              value={this.state.salary1}
                              maxLength="50"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No HP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="hp1" 
                              onChange={this.onChange} 
                              value={this.state.hp1}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address1"
                              maxLength="200"
                              value={this.state.address1}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group>
                          {/*this.showRemove()}
                          {this.showGuardian()*/}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}}  >Next</Button>
                          </div>
                          </Form.Group>

                         
                          </div>
                          :""
                        }

                        {this.state.parent_status_id === '3' ?
                          <div>

                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data {this.state.guardian_id_name2}</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname2"
                              maxLength="200"
                              value={this.state.fullname2}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik2" 
                              onChange={this.onChange} 
                              value={this.state.nik2}
                              maxLength="16"
                              style={{ width : '100%'}} 
                              required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir *</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob2}
                              value={this.state.dob2}
                              onChange={this.onChangeDate2}
                              readOnly={true}
                              style={{width:200}}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_guardian_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_guardian_id2 + '_' + this.state.religion_guardian_id2_name} required>
                              <option value="">-Pilih Agama-</option>
                              {console.log(this.state.religion_guardian_id2)}
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" name="status_guardian_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.status_guardian_id2 + '_' + this.state.status_guardian_id2_name} required>
                              <option value="">-Pilih Status-</option>
                              {this.createSelectStatus()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pendidikan Terakhir *</Form.Label>
                            <Form.Control as="select" name="education_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.education_id2 + '_' + this.state.education_id2_name} required>
                              <option value="">-Pilih Pendidikan-</option>
                              {this.createSelectPendidikan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pekerjaan *</Form.Label>
                            <Form.Control as="select" name="occupation_id2" onChange={this.onDropdownSelected.bind(this)} value={this.state.occupation_id2 + '_' + this.state.occupation_id2_name} required>
                              <option value="">-Pilih Pekerjaan-</option>
                              {this.createSelectPekerjaan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penghasilan (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              name="salary2" 
                              onChange={this.onChangeNom} 
                              value={this.state.salary2}
                              maxLength="50"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No HP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="hp2" 
                              onChange={this.onChange} 
                              value={this.state.hp2}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address2"
                              maxLength="200"
                              value={this.state.address2}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group>
                      
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}}  >Next</Button>
                          </div>
                          </Form.Group>
                          </div>
                          :""
                        }

                          {this.state.parent_status_id === '4' ?
                          <div>

                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data {this.state.guardian_id_name3}</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Lengkap *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="fullname3"
                              maxLength="200"
                              value={this.state.fullname3}
                              onChange={this.onChange} required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>NIK (Nomor Induk Kependudukan) => cek di Kartu Keluarga *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nik3" 
                              onChange={this.onChange} 
                              value={this.state.nik3}
                              maxLength="16"
                              style={{ width : '100%'}} 
                              required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tanggal Lahir *</Form.Label>
                            <DatePickerInput
                              displayFormat='DD-MM-YYYY'
                              returnFormat='YYYY-MM-DD'
                              className='my-react-component'
                              defaultValue={this.state.dob3}
                              value={this.state.dob3}
                              onChange={this.onChangeDate3}
                              readOnly={true}
                              style={{width:200}}
                            />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Agama *</Form.Label>
                            <Form.Control as="select" name="religion_guardian_id3" onChange={this.onDropdownSelected.bind(this)} value={this.state.religion_guardian_id3 + '_' + this.state.religion_guardian_id3_name} required>
                              <option value="">-Pilih Agama-</option>
                              {console.log(this.state.religion_guardian_id3)}
                              {this.createSelectReligion()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Status *</Form.Label>
                            <Form.Control as="select" name="status_guardian_id3" onChange={this.onDropdownSelected.bind(this)} value={this.state.status_guardian_id3 + '_' + this.state.status_guardian_id3_name} required>
                            <option value="">-Pilih Status-</option>
                              {this.createSelectStatus()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pendidikan Terakhir *</Form.Label>
                            <Form.Control as="select" name="education_id3" onChange={this.onDropdownSelected.bind(this)} value={this.state.education_id3 + '_' + this.state.education_id3_name} required>
                              {this.createSelectPendidikan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pekerjaan *</Form.Label>
                            <Form.Control as="select" name="occupation_id3" onChange={this.onDropdownSelected.bind(this)} value={this.state.occupation_id3 + '_' + this.state.occupation_id3_name} required>
                              {this.createSelectPekerjaan()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penghasilan (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0) </Form.Label>
                            <Form>
                            <CurrencyFormat 
                              name="salary3" 
                              onChange={this.onChangeNom} 
                              value={this.state.salary3}
                              maxLength="50"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>No HP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="hp3" 
                              onChange={this.onChange} 
                              value={this.state.hp3}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat *</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="address3"
                              maxLength="200"
                              value={this.state.address3}
                              onChange={this.onChange} required/>
                          </Form.Group>

                          <Form.Group>
                          {/*this.showRemove()}
                          {this.showGuardian()*/}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" block style={{margin:5, height:50}}  >Next</Button>
                          </div>
                          </Form.Group>
                          </div>
                          :""
                        }

                        
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;