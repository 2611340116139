import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        name : '',
        class : '',
    }
  }

  componentDidMount() {
    /*if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }*/
  }

  createSelectAchieveType() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveType;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectAchieveRank() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveRank;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectClass() {
    // ini valuenya
    let items = [];          
         items.push(
         <option value="7">7</option>,
         <option value="8">8</option>,
         <option value="9">9</option>); 
         
    return items;
    
  }

  showRemove(){
    if(localStorage.getItem('reg5')){
      return(
        <Button onClick={this.handleClear} block style={{margin:5, height:30, width: 100}}>Clear Data</Button>
      );
    }
  }

  showPrestasi() {
    if(localStorage.getItem('reg5')){
      let guardian = JSON.parse(localStorage.getItem('reg5'));
      console.log(localStorage.getItem('reg5'));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) { 
        showitem.push(
          <div key={guardian[i].name}>
            <hr/>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama Siswa </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Kelas </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].class} </Form.Label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value.split('_')[0], [e.target.name + "_name"]: e.target.value.split('_')[1]});
  }

  onDropdownSelected2(e) {    
    this.setState({class: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();    
    
    window.location.href="/pendaftaran6";
    
  }

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));
    let item =[];
    if(localStorage.getItem('reg5')){
      item = JSON.parse(localStorage.getItem('reg5'));
    }
    item.push(this.state);

    
    console.log("score " + JSON.stringify(item));

    localStorage.setItem('reg5', JSON.stringify(item));
    
    window.location.href="/pendaftaran5";
  }

  handleClear = () =>{
    localStorage.removeItem('reg5');
    window.location.href="/pendaftaran5";
  }

  handleBack = () => {
    window.location.href="/pendaftaran4";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 5</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleAdd}>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Saudara yang masih bersekolah di SMP PL Domenico Savio</Form.Label>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name}
                              onChange={this.onChange}
                              required />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Kelas</Form.Label>
                            <Form.Control as="select" onChange={this.onDropdownSelected2.bind(this)} value={this.state.class}>
                                <option value="">-Pilih Kelas-</option>
                                {this.createSelectClass()}
                              </Form.Control>
                            
                            {/*<Form.Control 
                              type="Text"
                              name="class"
                              maxLength="10"
                              value={this.state.class}
                              onChange={this.onChange}
                            required  />*/}
                          </Form.Group>

                          <div className='rowHorizontal'>
                            <Button type="submit"  block style={{margin:5, height:50}}>Tambah</Button>
                          </div>
                          

                          {this.showRemove()}
                          {this.showPrestasi()}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button onClick={this.handleSubmit} block style={{margin:5, height:50}} >Next</Button>
                          </div>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;