import React, { Component } from 'react';
import { Breadcrumb, Table, Button} from 'react-bootstrap';
import ReactExport from "react-export-excel";
import 'moment/locale/id.js';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');


class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        data: '',
    }
  }

handleClick = param => e => {
  e.preventDefault();
  localStorage.setItem('student_id', param)
  window.open( "/detailstudent", '_blank');

}

  componentDidMount(){

    
    console.log((localStorage.getItem('data_report')))
    let data_report = JSON.parse(localStorage.getItem('data_report'));

    
    let data = []
    data_report.map((item) =>{
      let items =  Object.create({});

    
      items.fullname = item.fullname;
      items.pendaftaran =item.year + '-' + item.registration_type_id  + '-' +  item.registration_number;

      items.jadwal = item.schedule[0] && item.schedule[0].schedule_test_at?
                     moment(item.schedule[0].schedule_test_at, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'):'';
      items.ruangan = item.schedule[0]?
                      item.schedule[0].classroom_id:'';
     
      items.note = item.note_verification?
                   item.note_verification:'';
      items.status = item.is_reserve==='1'?'Cadangan':'';

      if(item.score && item.score.length){
        items.mtk11 = item.score[0].score;
        items.mtk12 = item.score[1].score;
        items.mtk21 = item.score[2].score;
        items.mtk22 = item.score[3].score;

        items.bindo11 = item.score[4].score;
        items.bindo12 = item.score[5].score;
        items.bindo21 = item.score[6].score;
        items.bindo22 = item.score[7].score;

        items.ipa11 = item.score[8].score;
        items.ipa12 = item.score[9].score;
        items.ipa21 = item.score[10].score;
        items.ipa22 = item.score[11].score;

        items.ips11 = item.score[12].score;
        items.ips12 = item.score[13].score;
        items.ips21 = item.score[14].score;
        items.ips22 = item.score[15].score;

        
      }else{
        items.mtk11 ='0';
        items.mtk12 ='0';
        items.mtk21 ='0';
        items.mtk22 ='0';

        items.bindo11 ='0';
        items.bindo12 ='0';
        items.bindo21 ='0';
        items.bindo22 ='0';

        items.ipa11 ='0';
        items.ipa12 ='0';
        items.ipa21 ='0';
        items.ipa22 ='0';

        items.ips11 ='0';
        items.ips12 ='0';
        items.ips21 ='0';
        items.ips22 ='0';

      }

      items.avg = item.scoreAvg;
      items.total = item.scoreTotal;
    
    
      data.push(items);
    })
    console.log(data);


    this.setState({report:data}, () => {this.setState({is_show: true})

    })

  }

  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Jalur Test</Breadcrumb.Item>
                          </Breadcrumb>

                          {this.state.is_show?
                          <ExcelFile element={<Button block style={{margin:5, height:50}}>Download Data</Button>}>
                            <ExcelSheet data={this.state.report} name="Report Wawancara">
                                <ExcelColumn label="Nama Lengkap" value="fullname"/>
                                <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                                <ExcelColumn label="Ruang Test" value="ruangan"/>
                                <ExcelColumn label="Jadwal Test" value="jadwal"/>
                                <ExcelColumn label="Status" value="status"/>
                                <ExcelColumn label="Catatan Tentang Pendaftar" value="note"/>

                                <ExcelColumn label="IV SEM1 MAT"value="mtk11"/>
                                <ExcelColumn label="IV SEM1 BINDO"value="bindo11"/>
                                <ExcelColumn label="IV SEM1 IPA"value="ipa11"/>
                                <ExcelColumn label="IV SEM1 IPS"value="ips11"/>

                                <ExcelColumn label="IV SEM2 MAT"value="mtk12"/>
                                <ExcelColumn label="IV SEM2 BINDO"value="bindo12"/>
                                <ExcelColumn label="IV SEM2 IPA"value="ipa12"/>
                                <ExcelColumn label="IV SEM2 IPS"value="ips12"/>

                                <ExcelColumn label="V SEM1 MAT"value="mtk21"/>
                                <ExcelColumn label="V SEM1 BINDO"value="bindo21"/>
                                <ExcelColumn label="V SEM1 IPA"value="ipa21"/>
                                <ExcelColumn label="V SEM1 IPS"value="ips21"/>

                                <ExcelColumn label="V SEM2 MAT"value="mtk22"/>
                                <ExcelColumn label="V SEM2 BINDO"value="bindo22"/>
                                <ExcelColumn label="V SEM2 IPA"value="ipa22"/>
                                <ExcelColumn label="V SEM2 IPS"value="ips22"/>

                                <ExcelColumn label="Total Nilai"value="total"/>
                                <ExcelColumn label="Nilai Rata-Rata"value="avg"/>

                               

                            </ExcelSheet>
                          </ExcelFile>
                          :''}

                          <div className="box-body">
                            <div className="row">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nama Lengkap (Nama dapat diklik)</th> 
                                  <th>Nomor Pendaftaran</th>
                                  <th>Ruang Test</th>
                                  <th>Jadwal Test</th>
                                  <th>Status</th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                              {data_report.map((item) =>{
                                  console.log(item)
                                  
                                  counter++;
                              return(
                                <tr>
                                  <td>{counter}</td>
                                  <td style={{color : 'blue'}} onClick ={this.handleClick(item.id)}>{item.fullname}</td>
                                  <td>{item.year + '-' + item.registration_type_id  + '-' +  item.registration_number }</td>
                                  <td>{item.schedule[0]?
                                       item.schedule[0].classroom_id:''}</td>
                                  <td>{item.schedule[0] && item.schedule[0].schedule_test_at?
                                       moment(item.schedule[0].schedule_test_at, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'):''} </td>
                                  <td>{item.is_reserve === '1'? 'Cadangan':''}</td>
                                </tr>
                              )
                             })}
                             </tbody>
                            </Table>
                            </div>
                          </div>
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;