import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Global from './components/Global';
import swal from 'sweetalert';
import Cookies from 'js-cookie'
import { usePromiseTracker } from "react-promise-tracker";
import Loader from 'react-loader-spinner';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
      promiseInProgress && 
        <div
            style={{
              width: "100%",
              height: "100",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: '#fff',
            }}>
            <Loader type="ThreeDots" color="#3c8dbc" height="100" width="100" />
        </div>
    );  
}

global.variable = Global;
global.swal = swal;
global.cookies = Cookies;
global.loading = LoadingIndicator;

ReactDOM.render(
    <div>
        <App />
        {<LoadingIndicator/>}
    </div>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import './index.css';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Routes />, document.getElementById('root'));
serviceWorker.unregister();
*/