import React, { Component } from 'react';
import { Breadcrumb, Table, Button} from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        data: '',
        totalnominalupp:'',
        totalhargaseragam:'',
    }
  }
  handleClick = param => e => {
    e.preventDefault();
    // console.log(param)
    localStorage.setItem('student_id', param)
    window.open( "/detailseragam", '_blank');
  }
  componentDidMount(){

    

    /*this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0], original_name : '', upload_file : ''});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }

     
      
    })*/

    

    const data = { flow : localStorage.getItem('DATA_FLOW'), study_year : localStorage.getItem('DATA_YEAR')}
    console.log(data)
    fetch(global.variable.LINK_API + 'report/uniform',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data)
      
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
      let data =[]
    resData.data.map((item) =>{
      let items =  Object.create({});
      items.fullname = item.fullname;
      items.pendaftaran =item.year + '-' + item.registration_type_id  + '-' +  item.registration_number;
      items.hp = item.hp;

      items.namaproduk1 = resData.paramUniform[0].name;
      items.ukuran1 = "-";
      items.jumlah1 = "0";

      items.namaproduk2 = resData.paramUniform[1].name;
      items.ukuran2 = "-";
      items.jumlah2 = "0";

      items.namaproduk3 = resData.paramUniform[2].name;
      items.ukuran3 = "-";
      items.jumlah3 = "0";


      items.namaproduk4 = resData.paramUniform[3].name;
      items.ukuran4 = "-";
      items.jumlah4 = "0";

      items.namaproduk5 = resData.paramUniform[4].name;
      items.ukuran5 = "-";
      items.jumlah5 = "0";

      items.namaproduk6 = resData.paramUniform[5].name;
      items.ukuran6 = "-";
      items.jumlah6 = "0";

      items.namaproduk7 = resData.paramUniform[6].name;
      items.ukuran7 = "-";
      items.jumlah7 = "0";

      items.namaproduk8 = resData.paramUniform[7].name;
      items.ukuran8 = "-";
      items.jumlah8 = "0";

      items.namaproduk9 = resData.paramUniform[8].name;
      items.ukuran9 = "-";
      items.jumlah9 = "0";

      items.namaproduk10 = resData.paramUniform[9].name;
      items.ukuran10 = "-";
      items.jumlah10 = "0";

      items.namaproduk11 = resData.paramUniform[10].name;
      items.ukuran11 = "-";
      items.jumlah11 = "0";

      items.namaproduk12 = resData.paramUniform[11].name;
      items.ukuran12 = "-";
      items.jumlah12 = "0";
      items.border12 = "-";

      items.namaproduk13 = resData.paramUniform[12].name;
      items.ukuran13 = "-";
      items.jumlah13 = "0";

      items.namaproduk14 = resData.paramUniform[13].name;
      items.ukuran14 = "-";
      items.jumlah14 = "0";

      items.namaproduk15 = resData.paramUniform[14].name;
      items.ukuran15 = "-";
      items.jumlah15 = "0";
      
      items.namaproduk16 = resData.paramUniform[15].name;
      items.ukuran16 = "-";
      items.jumlah16 = "0";

      items.namaproduk17 = resData.paramUniform[16].name;
      items.ukuran17 = "-";
      items.jumlah17 = "0";

      items.namaproduk18 = resData.paramUniform[17].name;
      items.ukuran18 = "-";
      items.jumlah18 = "0";

      items.namaproduk19 = resData.paramUniform[18].name;
      items.ukuran19 = "-";
      items.jumlah19 = "0";

      items.namaproduk20 = resData.paramUniform[19].name;
      items.ukuran20 = "-";
      items.jumlah20 = "0";


      for (let i = 0; i < item.uniform_data.length; i++) {
        if(item.uniform_data[i].uniform_id == 1){
          items.ukuran1 = item.uniform_data[i].size;
          items.jumlah1 = item.uniform_data[i].qty;
        }
        if(item.uniform_data[i].uniform_id == 2){
          items.ukuran2 = item.uniform_data[i].size;
          items.jumlah2= item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 3){
          items.ukuran3 = item.uniform_data[i].size;
          items.jumlah3= item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 4){
          items.ukuran4 = item.uniform_data[i].size;
          items.jumlah4 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 5){
          items.ukuran5 = item.uniform_data[i].size;
          items.jumlah5 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 6){
          items.ukuran6 = item.uniform_data[i].size;
          items.jumlah6 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 7){
          items.ukuran7 = item.uniform_data[i].size;
          items.jumlah7 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 8){
          items.ukuran8 = item.uniform_data[i].size;
          items.jumlah8 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 9){
          items.ukuran9 = item.uniform_data[i].size;
          items.jumlah9 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 10){
          items.ukuran10 = item.uniform_data[i].size;
          items.jumlah10 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 11){
          items.ukuran11 = item.uniform_data[i].size;
          items.jumlah11 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 12){
          items.ukuran12 = item.uniform_data[i].size;
          items.jumlah12 = item.uniform_data[i].qty;
          items.border12 = item.uniform_data[i].name;
        }

        if(item.uniform_data[i].uniform_id == 13){
          items.ukuran13 = item.uniform_data[i].size;
          items.jumlah13 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 14){
          items.ukuran14 = item.uniform_data[i].size;
          items.jumlah14 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 15){
          items.ukuran15 = item.uniform_data[i].size;
          items.jumlah15 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 16){
          items.ukuran16 = item.uniform_data[i].size;
          items.jumlah16 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 17){
          items.ukuran17 = item.uniform_data[i].size;
          items.jumlah17 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 18){
          items.ukuran18 = item.uniform_data[i].size;
          items.jumlah18 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 19){
          items.ukuran19 = item.uniform_data[i].size;
          items.jumlah19 = item.uniform_data[i].qty;
        }

        if(item.uniform_data[i].uniform_id == 20){
          items.ukuran20 = item.uniform_data[i].size;
          items.jumlah20 = item.uniform_data[i].qty;
        }
      }


     

     

      


      data.push(items)
    })

    this.setState({report:data}, () => {this.setState({is_show: true})
  })
      console.log(resData);
     
      // console.log(localStorage.getItem('uniform1'));
       
    })

    

    
 
  }


  


  render() {
    //let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    let nominal =0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Pemesanan Seragam</Breadcrumb.Item>
                          </Breadcrumb>

                          {this.state.is_show?
                          <ExcelFile element={<Button block style={{margin:5, height:50}}>Download Data</Button>}>
                            <ExcelSheet data={this.state.report} name="Report Wawancara">
                                <ExcelColumn label="Nama Lengkap" value="fullname"/>
                                <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                                <ExcelColumn label="Nomor Handphone" value="hp"/>
                                <ExcelColumn label="Nama Produk 1" value="namaproduk1"/>
                                <ExcelColumn label="Ukuran " value="ukuran1"/>
                                <ExcelColumn label="Jumlah " value="jumlah1"/>

                                <ExcelColumn label="Nama Produk 2" value="namaproduk2"/>
                                <ExcelColumn label="Ukuran " value="ukuran2"/>
                                <ExcelColumn label="Jumlah " value="jumlah2"/>

                                <ExcelColumn label="Nama Produk 3" value="namaproduk3"/>
                                <ExcelColumn label="Ukuran " value="ukuran3"/>
                                <ExcelColumn label="Jumlah " value="jumlah3"/>

                                <ExcelColumn label="Nama Produk 4" value="namaproduk4"/>
                                <ExcelColumn label="Ukuran " value="ukuran4"/>
                                <ExcelColumn label="Jumlah " value="jumlah4"/>

                                <ExcelColumn label="Nama Produk 5" value="namaproduk5"/>
                                <ExcelColumn label="Ukuran " value="ukuran5"/>
                                <ExcelColumn label="Jumlah " value="jumlah5"/>

                                <ExcelColumn label="Nama Produk 6" value="namaproduk6"/>
                                <ExcelColumn label="Ukuran " value="ukuran6"/>
                                <ExcelColumn label="Jumlah " value="jumlah6"/>

                                <ExcelColumn label="Nama Produk 7" value="namaproduk7"/>
                                <ExcelColumn label="Ukuran " value="ukuran7"/>
                                <ExcelColumn label="Jumlah " value="jumlah7"/>

                                <ExcelColumn label="Nama Produk 8" value="namaproduk8"/>
                                <ExcelColumn label="Ukuran " value="ukuran8"/>
                                <ExcelColumn label="Jumlah " value="jumlah8"/>

                                <ExcelColumn label="Nama Produk 9" value="namaproduk9"/>
                                <ExcelColumn label="Ukuran " value="ukuran9"/>
                                <ExcelColumn label="Jumlah " value="jumlah9"/>

                                <ExcelColumn label="Nama Produk 10" value="namaproduk10"/>
                                <ExcelColumn label="Ukuran " value="ukuran10"/>
                                <ExcelColumn label="Jumlah " value="jumlah10"/>

                                <ExcelColumn label="Nama Produk 11" value="namaproduk11"/>
                                <ExcelColumn label="Ukuran " value="ukuran11"/>
                                <ExcelColumn label="Jumlah " value="jumlah11"/>

                                <ExcelColumn label="Nama Produk 12" value="namaproduk12"/>
                                <ExcelColumn label="Bordir Nama " value="border12"/>
                                <ExcelColumn label="Jumlah " value="jumlah12"/>


                                <ExcelColumn label="Nama Produk 13" value="namaproduk13"/>
                                <ExcelColumn label="Ukuran" value="ukuran13"/>
                                <ExcelColumn label="Jumlah " value="jumlah13"/>

                                <ExcelColumn label="Nama Produk 14" value="namaproduk14"/>
                                {/* <ExcelColumn label="Ukuran 14" value="ukuran14"/> */}
                                <ExcelColumn label="Jumlah " value="jumlah14"/>

                                <ExcelColumn label="Nama Produk 15" value="namaproduk15"/>
                                {/* <ExcelColumn label="Ukuran 15" value="ukuran15"/> */}
                                <ExcelColumn label="Jumlah " value="jumlah15"/>

                                <ExcelColumn label="Nama Produk 16" value="namaproduk16"/>
                                <ExcelColumn label="Ukuran" value="ukuran16"/>
                                <ExcelColumn label="Jumlah " value="jumlah16"/>

                                <ExcelColumn label="Nama Produk 17" value="namaproduk17"/>
                                <ExcelColumn label="Ukuran" value="ukuran17"/>
                                <ExcelColumn label="Jumlah " value="jumlah17"/>

                                <ExcelColumn label="Nama Produk 18" value="namaproduk18"/>
                                <ExcelColumn label="Ukuran" value="ukuran18"/>
                                <ExcelColumn label="Jumlah " value="jumlah18"/>

                                <ExcelColumn label="Nama Produk 19" value="namaproduk19"/>
                                <ExcelColumn label="Ukuran" value="ukuran19"/>
                                <ExcelColumn label="Jumlah " value="jumlah19"/>

                                <ExcelColumn label="Nama Produk 20" value="namaproduk20"/>
                                <ExcelColumn label="Ukuran" value="ukuran20"/>
                                <ExcelColumn label="Jumlah " value="jumlah20"/>
                                

                            </ExcelSheet>
                          </ExcelFile>
                           :''}
                             
                          <div className="box-body">
                            <div className="row">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nama Lengkap (Nama dapat diklik)</th> 
                                  <th>Nomor Pendaftaran</th>
                                  <th>Nomor Handphone</th>
                                  <th>Total</th>
                                 
                                </tr>
                              </thead>
                              <tbody>
                                
                              {this.state.is_showdata?
                              
                              this.state.uniform.map((item) =>{
                                
                                let totalnominal =0;
                                
                                for(let i=0; i<item.uniform_data.length;i++){
                                 totalnominal += item.uniform_data[i].qty*item.uniform_data[i].price
                                }
                                nominal += totalnominal;
                                
                                // console.log(nominal);
                                // this.setState({totalhargaseragam : nominal})
                                  
                                  counter++;
                              return(
                                <tr>
                                  <td>{counter}</td>
                                  <td style={{color : 'blue'}} onClick={this.handleClick(item.id)}> {item.fullname}</td>
                                  <td>{item.year + '-' + item.registration_type_id  + '-' +  item.registration_number }</td>
                                  <td>{item.hp}</td>
                              <td><CurrencyFormat
                                    prefix={'Rp. '}
                                    readOnly
                                    value={totalnominal}
                                    maxLength="25"  thousandSeparator={true}
                                    style={{ width : '100%'}} displayType={'text'}/>
                                </td>
                                </tr>
                              )
                             }) 
                            :''}
                            
                            
                             </tbody>
                             

                                <tr>
                                  
                                  <td colSpan = '4'>TOTAL NOMINAL</td>
                                  <td>
                                    <CurrencyFormat
                                      input="number"
                                      prefix={'Rp. '}
                                      readOnly
                                      value={nominal}
                                      maxLength="25" thousandSeparator={true}
                                      style={{ width: '100%' }} displayType={'text'} />&nbsp;</td>
                                </tr>
                                
                            </Table>
                            
                            </div>
                          </div>
                          
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;