import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        note : '',
        encrypt_64 : '',
        original_name : '',
    }
  }


  handleUpload= event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan diupload?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Upload', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        return fetch(global.variable.LINK_API + 'uploadbuktiupp/store',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Sukses Upload', "success")
          .then((value) => {
            window.location.href="/uploadbuktiuppdetail";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

    }
  
  };

  getFiles(files){
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
      }
      else{
        this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
    }
  }

  onChangeHandler=event=>{
    var file = event.target.files[0];

    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }else if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
      console.log(file);
       this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }
      if(this.state.upload_upp){
        let totalnominal = 0;
        for(let i =0; i < this.state.upload_upp.length; i++){
          totalnominal += this.state.upload_upp[i].nominal;
        }
        this.setState({total:totalnominal})
      }

      let potongan2 = this.state.data_bayar[0].nominal * this.state.data_bayar[0].potongan / 100

      let nominal2 = this.state.data_bayar[0].nominal - potongan2;
                     this.setState({hasil:nominal2});
      
      let sisanominal = this.state.hasil - this.state.total;
                        this.setState({sisa:sisanominal});

      let persentase = (this.state.total / this.state.hasil * 100).toFixed(2);
                        this.setState({persen:persentase})
      
       
    })
  }
    
  



  fileUploadHandler = event => {

    event.preventDefault();


      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        // data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('flow_id', '9')
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)
        return fetch(global.variable.LINK_API + 'flow/update',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
          .then((value) => {
            window.location.href="/uploadbuktiupp";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    
 
  };

  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open("/image/"+param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }
  
  formupload(){
    let guardian = this.state.upload_upp;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Bukti {this.state.upload_upp[i].counter}</Form.Label>
       
        <div className='rowHorizontal'>
        {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}
        
        <Link  target="_blank" onClick={this.download(this.state.upload_upp[i].id)}>Lihat Bukti</Link>

        {this.state.upload_upp[i].nominal !==0?
        <div>
          <Form.Label style={{marginLeft:20}}><CurrencyFormat  displayType={'text'} prefix ={'Rp.'} thousandSeparator={true} value={this.state.upload_upp[i].nominal}/></Form.Label>
        </div>
        :''}
        </div>
      </Form.Group>
      )
    }

    return showitem;
  }


  Buttonsub(){
    let guardian = this.state.upload_upp;
    let showitem = [];
    //console.log(guardian);
    let show = false;

    for (let i = 0; i < guardian.length; i++) { 
      
      if (this.state.upload_upp[i] && this.state.upload_upp[i].verify_by ===""){
        show =true}
      

    }
    showitem.push(
      <Form.Group controlId="exampleForm.ControlSelect1">
      {show?
      <div>
        <div className='rowHorizontal'>
       <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
        <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
      </div>
      </div>
      :''}
      
    </Form.Group>
    )

    return showitem;
  }



  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleSubmit = event => {
    event.preventDefault();
    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan disubmit?',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Submit', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 9, note: this.state.note, encrypt_64 : this.state.encrypt_64 }
        //console.log(JSON.stringify(data));
        return fetch(global.variable.LINK_API + 'flow/update',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
          },
          body: JSON.stringify(data), 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Silahkan menunggu proses verifikasi dan validasi dokumen anda dalam waktu maksimal 24 jam', "success")
          .then((value) => {
            window.location.href="/uploadbuktiUpp";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

      
    }
  }

  handleBack = () => {
    window.location.href="/uploadbukti";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Upload Bukti UPP</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.fileUploadHandler}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>UPP</Form.Label>
                            <Form>
                              <CurrencyFormat value={this.state.hasil} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
                            </Form>
                          </Form.Group>

                          <Form.Group controlId="exampleForm.COntrolInput1">
                            <Form.Label>Total nominal yang telah dibayarkan</Form.Label>
                            <Form>
                              <CurrencyFormat value={this.state.total} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.COntrolInput1">
                            <Form.Label>Sisa nominal yang belum dibayar</Form.Label>
                            <Form>
                              <CurrencyFormat value={this.state.sisa} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.COntrolInput1">
                            <Form.Label>Persentase yang sudah dibayar</Form.Label>
                            <Form>
                            <Progress
                              percent={this.state.persen}
                            />
                            </Form>
                          </Form.Group>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="note"
                              maxLength="50"
                              value={this.state.note}
                              onChange={this.onChange}  />
                          </Form.Group>


                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload file gambar (*jpg dan *.png) atau pdf</Form.Label>
                            <input type="file" name="file" className="form-control" onChange={this.onChangeHandler}/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label style={{ fontSize:18, color:'red'}}>*Klik tombol submit setelah upload</Form.Label>
                            
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleUpload} block style={{margin:5, height:50}}>Upload</Button>
                          </div>
                          </Form.Group>
                          <Form.Group>
                          {this.state.upload_upp && this.state.upload_upp.length? 
                          this.formupload()
                           : ''}
                          </Form.Group>

                          <Form.Group>
                          {this.state.upload_upp && this.state.upload_upp.length?
                          this.Buttonsub()
                          :''}
                          
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;