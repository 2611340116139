import React, { Component } from 'react';
import { Breadcrumb, Table, Button} from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Home extends Component {

  constructor(props){
    super(props);

    this.state={
        data_student : '',
        is_show: false,
        data: '',
        totalnominalupp:'',
        totalhargaseragam:'',
    }
  }
  handleClick = param => e => {
    e.preventDefault();
    // console.log(param)
    localStorage.setItem('student_id', param)
    window.open( "/detailangket", '_blank');
  }
  componentDidMount(){
   
    const data = { study_year : localStorage.getItem('DATA_YEAR')}

    fetch(global.variable.LINK_API + 'report/questionnaire',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
      let data =[]
    resData.data.map((item) =>{
      let items =  Object.create({});
      items.fullname = item.fullname;
      items.pendaftaran =item.year + '-' + item.registration_type_id  + '-' +  item.registration_number;
      items.hp = item.hp;

      items.p1 = item.questionnaire[0].option_name;
      items.p2 = item.questionnaire[1].option_name;
      items.p3 = item.questionnaire[2].option_name;
      items.p4 = item.questionnaire[3].option_name;
      items.p5 = item.questionnaire[4].option_name;
      items.p6 = item.questionnaire[5].option_name;
      items.p7 = item.questionnaire[6].option_name;
      items.p8 = item.questionnaire[7].option_name;
      items.p9 = item.questionnaire[8].option_name;
      items.p10 = item.questionnaire[9].option_name;

      items.p11 = item.questionnaire[10].option_name;
      items.p12 = item.questionnaire[11].option_name;
      items.p13 = item.questionnaire[12].option_name;
      items.p14 = item.questionnaire[13].option_name;
      items.p15 = item.questionnaire[14].option_name;
      items.p16 = item.questionnaire[15].option_name;
      items.p17 = item.questionnaire[16].option_name;
      items.p18 = item.questionnaire[17].option_name;
      items.p19 = item.questionnaire[18].option_name;
      items.p20 = item.questionnaire[19].option_name;

      items.p21 = item.questionnaire[20].option_name;
      items.p22 = item.questionnaire[21].option_name;
      items.p23 = item.questionnaire[22].option_name;
      items.p24 = item.questionnaire[23].option_name;
      items.p25 = item.questionnaire[24].option_name;
      items.p26 = item.questionnaire[25].option_name;
      items.p27 = item.questionnaire[26].option_name;
      items.p28 = item.questionnaire[27].option_name;
      items.p29 = item.questionnaire[28].option_name;
      items.p30 = item.questionnaire[29].option_name;
      items.p31 = item.questionnaire[30].option_name;

      items.p34 = item.questionnaire[33].other;
      items.p35 = item.questionnaire[34].other;

      if(item.questionnaire[31].option_name ==="" || item.questionnaire[31].option_name ==="Lainnya"){
        items.p32 = item.questionnaire[31].other;
      }
      else{items.p32 = item.questionnaire[31].option_name;}
     
      if(item.questionnaire[32].option_name ==="" || item.questionnaire[32].option_name ==="Lainnya"){
        items.p33 = item.questionnaire[32].other;
      }
      else{items.p33 = item.questionnaire[32].option_name;}
    

     

      


      data.push(items)
    })

    this.setState({report:data}, () => {this.setState({is_show: true})
  })

     
      
      console.log(resData);
     
      // console.log(localStorage.getItem('uniform1'));
       
    })

    

    
 
  }


  


  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    let nominal =0;
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Angket </Breadcrumb.Item>
                          </Breadcrumb>

                          {this.state.is_show?
                          <ExcelFile element={<Button block style={{margin:5, height:50}}>Download Data</Button>}>
                            <ExcelSheet data={this.state.report} name="Report Wawancara">
                                <ExcelColumn label="Nama Lengkap" value="fullname"/>
                                <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran"/>
                                <ExcelColumn label="Nomor Handphone" value="hp"/>

                                <ExcelColumn label="SMP PL Domenico Savio adalah sekolah terbaik di Kota Semarang" value="p1"/>
                                <ExcelColumn label="SMP PL Domenico Savio adalah sekolah mahal" value="p2"/>
                                <ExcelColumn label="Biaya pendidikan di SMP PL Domenico Savio sepadan dengan kualitas pengajar dan proses pendampingan peserta didik" value="p3"/>
                                <ExcelColumn label="Biaya pendidikan di SMP PL Domenico Savio sepadan dengan prestasi peserta didik" value="p4"/>
                                <ExcelColumn label="Biaya pendidikan di SMP PL Domenico Savio sepadan dengan fasilitas sarana-prasarana yang ada di sekolah" value="p5"/>
                                <ExcelColumn label="Saya mendukung subsidi silang yang diterapkan di sekolah-sekolah Pangudi Luhur untuk membantu sekolah Pangudi Luhur lainnya yang memerlukan bantuan" value="p6"/>
                                <ExcelColumn label="Saya memperoleh informasi pendaftaran peserta didik baru SMP PL Domenico Savio dengan mudah" value="p7"/>
                                <ExcelColumn label="Saya memperoleh informasi pendaftaran peserta didik baru SMP PL Domenico Savio secara detail dan menyeluruh" value="p8"/>
                                <ExcelColumn label="Informasi dan media publikasi pendaftaran peserta didik baru SMP PL Domenico Savio mengakomodasi kebutuhan informasi yang saya perlukan" value="p9"/>
                                <ExcelColumn label="Mekanisme pendaftaran online di SMP PL Domenico Savio lebih mudah daripada pendaftaran manual" value="p10"/>
                                <ExcelColumn label="Jalur pendaftaran yang ada di SMP PL Domenico Savio variatif dan mengakomodasi berbagai prestasi peserta didik" value="p11"/>
                                <ExcelColumn label="Saya dapat melihat dengan mudah hasil verifikasi dan validasi berkas pendaftaran online di SMP PL Domenico Savio" value="p12"/>
                                <ExcelColumn label="Saya senang dengan pendaftaran online di SMP PL Domenico Savio karena tidak perlu membuang waktu untuk mendaftar" value="p13"/>
                                <ExcelColumn label="Saya merasa lebih mudah melakukan pembayaran via transfer / Virtual Account" value="p14"/>
                                <ExcelColumn label="Saya senang dengan layanan verifikasi dan validasi berkas pendaftaran yang ada di Sistem PPDB Online SMP PL Domenico Savio maksimal 1 x 24 jam" value="p15"/>
                                <ExcelColumn label="Waktu untuk wawancara finansial sesuai dengan waktu luang saya" value="p16"/>
                                <ExcelColumn label="Petugas wawancara finansial melayani saya dengan santun" value="p17"/>
                                <ExcelColumn label="Petugas wawancara finansial mau mendengarkan saya" value="p18"/>
                                <ExcelColumn label="Informasi terkait seragam (ukuran, harga, dll) pada sistem online SMP PL Domenico Savio cukup jelas dan detail" value="p19"/>
                                <ExcelColumn label="Saya senang dengan pemesanan seragam online karena tidak membuang waktu untuk mengukur seragam ke sekolah" value="p20"/>
                                <ExcelColumn label="Saya senang dengan pendaftaran online di SMP PL Domenico Savio karena meminimalisir penggunaan kertas untuk mendukung gerakan Go Green" value="p21"/>
                                <ExcelColumn label="Saya merasa gaptek dengan pendaftaran online" value="p22"/>
                                <ExcelColumn label="Saya merasa kesulitan memahami langkah-langkah yang harus dilakukan dalam pendaftaran online di SMP PL Domenico Savio" value="p23"/>
                                <ExcelColumn label="Pendaftaran online di SMP PL Domenico Savio membuat saya khawatir tidak terdaftar" value="p24"/>
                                <ExcelColumn label="Pendaftaran online di SMP PL Domenico Savio terhambat oleh jaringan" value="p25"/>
                                <ExcelColumn label="Saya merasa kesulitan mengakses situs pendaftaran online SMP PL Domenico Savio" value="p26"/>
                                <ExcelColumn label="Saya merasa kesulitan dalam mengunggah soft file lampiran di sistem pendaftaran online SMP PL Domenico Savio" value="p27"/>
                                <ExcelColumn label="Saya merasa kesulitan melakukan pendaftaran online karena kendala keterbatasan fasilitas yang saya miliki" value="p28"/>
                                <ExcelColumn label="Saya merasa kesulitan dalam pembayaran via transfer / Virtual Account" value="p29"/>
                                <ExcelColumn label="Dengan pemesanan seragam online, saya merasa khawatir ukuran seragam tidak pas" value="p30"/>
                                <ExcelColumn label="Saya tidak suka pendaftaran online karena tidak dapat melihat kondisi sekolah yang di daftar" value="p31"/>

                                <ExcelColumn label="Saya mengetahui informasi PPDB SMP PL Domenico Savio dari :" value="p32"/>

                                <ExcelColumn label="Alasan saya masuk SMP PL Domenico Savio adalah:" value="p33"/>
                                <ExcelColumn label="Saran / masukan untuk perbaikan Penerimaan Peserta Didik Baru di SMP PL Domenico Savio selanjutnya:" value="p34"/>
                                <ExcelColumn label="Saran / masukan untuk petugas wawancara finansial:" value="p35"/>
                            </ExcelSheet>
                          </ExcelFile>
                           :''}
                             
                          <div className="box-body">
                            <div className="row">
                            <Table striped bordered hover size="sm">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Nama Lengkap (Nama dapat diklik)</th> 
                                  <th>Nomor Pendaftaran</th>
                                  <th>Nomor Handphone</th>
                                 
                                 
                                </tr>
                              </thead>
                              <tbody>
                                
                              {this.state.is_showdata?
                              
                              this.state.uniform.map((item) =>{
                                
                                let totalnominal =0;
                                
                               
                                  
                                  counter++;
                              return(
                                <tr>
                                  <td>{counter}</td>
                                  <td style={{color : 'blue'}} onClick={this.handleClick(item.id)}> {item.fullname}</td>
                                  <td>{item.year + '-' + item.registration_type_id  + '-' +  item.registration_number }</td>
                                  <td>{item.hp}</td>
                             
                                </tr>
                              )
                             }) 
                            :''}
                            
                            
                             </tbody>
                             

                            </Table>
                            
                            </div>
                          </div>
                          
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Home;