import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel, Breadcrumb } from "react-bootstrap";

class Changepassword extends Component {

  constructor(props){
    super(props);

    this.state={
      password : '',
      new_password : '',
      new_password_confirmation : '',
    }
  }

  componentDidMount(){
    
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();   

    if(this.state.new_password!==this.state.new_password_confirmation){
      global.swal("Error", "Ketik ulang password baru tidak sesuai dengan password baru.", "info")
    }else if(this.state.password===this.state.new_password){
      global.swal("Error", "Password baru tidak boleh sama dengan password lama.", "info")
    }else{

      const data = {user_id : localStorage.getItem('userid'), password :  this.state.password, new_password : this.state.new_password}
      console.log(data)

      fetch(global.variable.LINK_API + 'password/change',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
      .then(response =>  response.json())
      .then(resData => {
        console.log(resData);
        if(resData.message==="Success"){
          
          global.swal("Success", 'Berhasil Ubah Password', "success")
          .then((value) => {
            window.location.href="/";
          });
        }else{
          global.swal("Error", resData.error, "info")
        }
        
      })
    }
  }

  back(){
    
    window.location.href="/home";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                    <div className="box">
                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Change Password</Breadcrumb.Item>
                          </Breadcrumb>
                                
                          <div className="box-body">
                          <form onSubmit={this.handleSubmit}>
                            <FormGroup controlId="user_id">
                              <FormLabel style={{fontSize:18}}>Silakan ubah password anda</FormLabel>
                            </FormGroup>
                            <FormGroup controlId="password" >
                              <FormLabel >Password lama</FormLabel >
                              <FormControl
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                type="password"
                                required
                              />
                            </FormGroup>
                            <FormGroup controlId="new_password" >
                              <FormLabel >Password baru</FormLabel >
                              <FormControl
                                name="new_password"
                                value={this.state.new_password}
                                onChange={this.handleChange}
                                type="password"
                                required
                              />
                            </FormGroup>
                            <FormGroup controlId="new_password_confirmation" >
                              <FormLabel >Ketik ulang password baru</FormLabel >
                              <FormControl
                                name="new_password_confirmation"
                                value={this.state.new_password_confirmation}
                                onChange={this.handleChange}
                                required
                                type="password"
                              />
                            </FormGroup>
                            <Button
                              block
                              type="submit"
                            >
                              Submit
                            </Button>

                            
                            <Button
                              block
                              onClick={this.back}
                              type="button">
                              Back
                            </Button>
                          </form>
                          </div>
                                
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </div>
    );
  }
}

export default Changepassword;