import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom'

const moment = require('moment');

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        note_verification : '',
    }
  }

  getFiles(files){
    this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        console.log(this.state);
      }
       
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleSubmit = event => {
    event.preventDefault();
    //console.log("state 1 " + JSON.stringify(this.state));

    //console.log(this.state.encrypt_64);

    console.log(JSON.stringify(this.state));

  }

  handleBack = () => {
    window.location.href="/verifikasi";
  }

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open("/image/"+param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')
    
    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  form1(){
    return (
      <div>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jalur</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.registration_type.name}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Lengkap</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.fullname}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nomor Registrasi</Form.Label>
          <Form.Control 
            type="Text"
            name="nomor_registrasi"
            maxLength="100"
            value={ this.state.year + '-' + this.state.registration_type_id  + '-' +  this.state.registration_number}
            disabled/>
        </Form.Group>
        {/*<Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Panggilan</Form.Label>
          <Form.Control 
            type="Text"
            name="nickname"
            maxLength="100"
            value={this.state.nickname}
            disabled />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Jenis Kelamin</Form.Label>
          <Form.Control 
            type="Text"
            name="gender"
            maxLength="100"
            value={this.state.gender.name}
            disabled />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>NIK</Form.Label>
            <Form.Control 
              type="Text"
              name="nik"
              maxLength="50"
              value={this.state.nik}
              disabled />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>NIS</Form.Label>
            <Form.Control 
              type="Text"
              name="nis"
              maxLength="50"
              value={this.state.nis}
              disabled />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>NISN</Form.Label>
            <Form.Control 
              type="Text"
              name="nisn"
              maxLength="50"
              value={this.state.nisn}
              disabled />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Tempat Lahir</Form.Label>
            <Form.Control 
              type="Text"
              name="pob"
              maxLength="100"
              value={this.state.pob}
              disabled />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Tanggal Lahir</Form.Label>
            <Form.Control 
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={moment(this.state.dob, 'YYYY-MM-DD').format('DD-MM-YYYY')}
              disabled />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>No Akta Kelahiran</Form.Label>
            <Form.Control 
              type="Text"
              name="birth_certificate"
              maxLength="50"
              value={this.state.birth_certificate}
              disabled />
          </Form.Group>*/}
          <Form.Group></Form.Group>
      </div>
    );
  }

  

  formbukti(){
    
    return(
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      this.state.upload_transaction?
      <Link  target="_blank" onClick={this.download(this.state.upload_transaction.id)}>Lihat Bukti Bayar</Link>
      :'Lewat VA '

    );
  }

  handleTest(){
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 4, note_verification : this.state.note_verification }

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        let m = moment();  
        m = moment(resData.data.schedule_test_at, 'YYYY-MM-DD').format('DD-MM-YYYY');
        global.swal("Success", 'Jadwal test adalah ' + m + '\n Pada pukul 13.00 – 15.00 WIB \nDi ruang ' +  resData.data.classroom_id, "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
  }

  handleConfirm= event =>{
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 3, note_verification : this.state.note_verification}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Konfirmasi Bukti Bayar', "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
  }

  handleTolak= event =>{

    global.swal({
      text: 'Apakah anda yakin akan ditolak?',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 99, note_reject : confirm}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'flow/update',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
    .catch(err => {
      console.log(err)
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });

    
    
  }

  handleReturn = event =>{
    global.swal({
      text: 'Alasan Bukti Bayar dikembalikan',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 2, note_return : confirm}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'flow/update',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
      })
      .then(response =>  response.json())
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          
          global.swal("Success", 'Berhasil Dikembalikan', "success")
          .then((value) => {
            window.location.href="/verifikasi";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Verifikasi Bukti Bayar</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          {this.state.is_show ? 
                          this.form1()
                           : ''}


                          {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Bukti Bayar</Form.Label>
                          </div>
                          : ''}

                          {this.state.is_show ? 
                          this.formbukti()
                           : ''}


                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleTolak} block style={{margin:5, height:50, backgroundColor:'red'}}>Tolak</Button>
                            <Button onClick={this.handleReturn} block style={{margin:5, height:50, backgroundColor:'red'}}>Kembalikan</Button>
                            <Button onClick={this.handleConfirm} block style={{margin:5, height:50}} >Confirm Bukti Bayar</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;