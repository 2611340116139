import React, { Component } from "react";
import { Form, Breadcrumb, Button, Table } from "react-bootstrap";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { isEmpty, map } from "lodash";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Logo from "../assets/images/logo-YPL.png";
import { trackPromise } from "react-promise-tracker";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MySwal = withReactContent(Swal);

class Bayar_pendaftaran_detail extends Component {
  constructor(props) {
    super(props);
    this._onSearchContent = this._onSearchContent.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onChecked = this._onChecked.bind(this);
    this._onUnchecked = this._onUnchecked.bind(this);
    this._onUncheckedAll = this._onUncheckedAll.bind(this);
    this.onBayar = this.onBayar.bind(this);
    this.detailPayments = this.detailPayments.bind(this);
    this.cancelPayments = this.cancelPayments.bind(this);
    this.refreshPayments = this.refreshPayments.bind(this);

    this.state = {
      student_id: "",
      is_show: false,
      form: {
        value: {
          detail_checked: [],
          subtotal: 0,
          total: 0,
          no_va: localStorage.getItem("DATA_JALUR").split("-")[0],
          name: localStorage.getItem("DATA_JALUR").split("-")[1],
          last_name: "",
          phone: "",
          email: "",
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
          cek_payment: "",
        },
        error: {
          detail_checked: "",
        },
      },
      filters: {
        jenis: "",
      },
      listinvoice: [],
      payload: [],
      param: {
        prm_jenis: [
          { label: "SPP", value: "rutin" },
          { label: "DPP/UPP", value: "nonrutin" },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
    });
    this._onSearchContent();
  }

  async _onSearchContent(params = {}) {
    await this._onUncheckedAll();

    await trackPromise(
      fetch(
        global.variable.LINK_API_AKADEMIS + "create-invoices/listRegisFee",
        {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            temps_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          }),
        }
      )
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ listinvoice: resData, is_show: true });
            this._onChecked(resData[0]);
          } else {
            global.swal("Error", "Invoice tidak ditemukan.", "info")
            .then((value) => {
              this.handleBack();
            });
          }
        })
        .catch((err) => {
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );

    fetch(global.variable.LINK_API_AKADEMIS + "payments/list-payment-gateway", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData) {
          this.setState({ listbank: resData });
        } else {
          this.setState({ is_show: false });
        }
      })
      .catch((err) => {
        if (err) {
          this.setState({ is_show: false });
          global.swal("Error", "Koneksi ke server gagal.", "info");
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.param.prm_jenis.length; i++) {
      items.push(
        <option key={i} value={this.state.param.prm_jenis[i].value}>
          {this.state.param.prm_jenis[i].label}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected(e) {
    const { target } = e;
    const { value, name } = target;

    this.setState(
      (prevState) => ({
        ...prevState,
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      })
      // () => this._onSearchContent()
    );
    const data = {
      filters: {
        jenis: e.target.value,
      },
      temps_id: this.state.student_id,
    };
  }

  async _onFormChange(event) {
    const { form } = this.state;
    const { cek_payment } = form.value;
    const { name, value, dataset, checked, type } = event.target;
    const {
      inputType = "text",
      inputArray = false,
      arrayPosition = 0,
      fieldName,
      arrayList,
      paymentId,
    } = dataset;

    // if (fieldName === "listinvoice" && checked == true) {
    //   await this._onUncheckedAll();
    // }
    if (name === "pg_code") {
      var admin_fee = Number(event.target.getAttribute("admin_fee"));
      var admin_percent = Number(event.target.getAttribute("admin_percent"));
      var bank_name = event.target.getAttribute("bank_name");
      if (admin_fee == 0 && admin_percent != 0) {
        admin_fee = Math.round(form.value.subtotal * (admin_percent / 100));
      }
      this.setState((prevState) => ({
        form: {
          value: {
            ...prevState.form.value,
            admin_fee: admin_fee,
            bank_name: bank_name,
          },
          error: {
            ...prevState.form.error,
          },
        },
      }));
    }
    this.setState(
      (prevState) => {
        let newList = [];
        let newListError = [];
        let formattedValue = value;

        // if (inputArray) {
        //   newList = prevState.form.value[fieldName];
        //   newListError = prevState.form.error[fieldName];
        //   newList[arrayPosition][name] = formattedValue;
        //   if (!isEmpty(newListError[arrayPosition])) {
        //     newListError[arrayPosition][name] = "";
        //   }
        // }
        // if (type === "checkbox") {
        //   formattedValue = checked ? "true" : "";
        //   if (formattedValue == "true") {
        //     for (let i = 0; i <= arrayPosition; i++) {
        //       this._onChecked(prevState[fieldName][i]);
        //     }
        //   } else {
        //     this._onUnchecked(arrayPosition);
        //   }
        // }
        return {
          form: {
            value: {
              ...prevState.form.value,
              ...(inputArray
                ? { [fieldName]: newList }
                : { [name]: formattedValue }),
            },
            error: {
              ...prevState.form.error,
              ...(inputArray ? { [fieldName]: newListError } : { [name]: "" }),
            },
          },
        };
      },
      () => {}
    );
  }

  _onChecked(data) {
    const { form } = this.state;
    const { detail_checked } = form.value;

    detail_checked.push({
      temps_id: data.temps_id,
      invoices_id: data.invoices_id,
      nominal: data.nominal,
      periode: data.periode,
    });

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          detail_checked,
        },
      },
    }));
  }

  _onUnchecked(idx) {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(idx, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  _onUncheckedAll() {
    this.setState((prevState) => {
      const list = prevState.form.value.detail_checked;
      list.splice(0, list.length);
      return {
        ...prevState,
        detail_checked: list,
      };
    });
  }

  // fungsi setelah bank di pilih
  async onBayar(e) {
    e.preventDefault();
    this.openModalRincian();
  }

  // fungsi setelah bank di pilih
  async onSendPayment() {
    const { form } = this.state;
    const { pg_code } = form.value;

    if (isEmpty(pg_code)) {
      global.swal(
        "Metode pembayaran  belum di pilih.",
        "Mohon dipilih terlebih dahulu",
        "info"
      );
    } else {
      if (pg_code == "mandiri_h2h") {
        this.openModalDetail(); // buka modal detail
      } else {
        await this.detailPayments(); // simpan pembayaran
        this.openModalDetail(); // buka modal detail
      }
    }
  }

  // fungsi simpan detail pembayaran
  async detailPayments() {
    const { form } = this.state;
    const { value } = form;

    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      html: "Tagihan sedang diproses",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    await trackPromise(
      fetch(global.variable.LINK_API_AKADEMIS + "payments/detail-payments", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            this.setState({ payload: resData });
          } else {
            global.swal("Error", "Koneksi gagal.", "info");
            Swal.close();
          }
        })
        .catch((err) => {
          Swal.close();
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  }

  // fungsi show detail setelah simpan detail pembayaran
  async onShowDetail(data) {
    this.openModalShowDetail(data); // buka modal detail
  }

  // fungsi batalkan detail pembayaran
  async cancelPayments(data) {
    Swal.fire({
      title: "Mohon Tunggu Sebentar !",
      html: "Tagihan sedang dibatalkan",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    await trackPromise(
      fetch(global.variable.LINK_API_AKADEMIS + "payments/cancel-payments", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((resData) => {
          if (!isEmpty(resData)) {
            if (resData.payment_status_code == "1") {
              global.swal(
                "Tagihan Belum Bisa Dibatalkan",
                resData.expired_date,
                "info"
              );
              Swal.close();
            } else {
              this.setState({ payload: [] });
              this.refreshPayments();
            }
          } else {
            global.swal("Error", "Koneksi gagal.", "info");
            Swal.close();
          }
        })
        .catch((err) => {
          this.setState({ payload: [] });
          Swal.close();
          if (err) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          } else {
            global.swal.stopLoading();
            global.swal.close();
          }
        })
    );
  }

  // fungsi refresh list pembayaran & close modal
  async refreshPayments() {
    this.setState((prevState) => ({
      payload: [],
      form: {
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
          subtotal: 0,
          total: 0,
        },
      },
    }));
    this._onSearchContent();
    MySwal.close();
  }

  async openModalRincian() {
    // reset bank jika sdh di pilih
    await this.setState((prevState) => ({
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
        },
      },
    }));
    MySwal.fire({
      title: <p>Rincian Pembayaran</p>,
      width: "350px",
      html: this.renderModalContent(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalMethod() {
    MySwal.fire({
      title: <p>Pilih Metode Pembayaran</p>,
      width: "550px",
      html: this.renderModalContentMethod(),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalDetail() {
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentDetail(),
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  openModalShowDetail(data) {
    MySwal.fire({
      width: "450px",
      html: this.renderModalContentShowDetail(data),
      showCancelButton: false,
      showConfirmButton: false,
    });
  }

  renderModalContent() {
    const { form, filters } = this.state;
    const { jenis } = filters;
    const { detail_checked, total } = form.value;

    var last = "";

    return (
      <div>
        <hr style={{ margin: "unset" }}></hr>
        <br></br>
        <Table striped bordered hover size="sm" style={{ marginBottom: "5px" }}>
          <thead>
            <tr>
              <th style={{ border: "none" }}>Jumlah</th>
              <th style={{ border: "none", textAlign: "right" }}>
                <CurrencyFormat
                  input="number"
                  prefix={"Rp. "}
                  readOnly
                  value={total}
                  maxLength="25"
                  thousandSeparator={true}
                  style={{ width: "100%" }}
                  displayType={"text"}
                />
                
              </th>
            </tr>
          </thead>
        </Table>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th colSpan="2">Detail Pembayaran</th>
            </tr>
          </thead>
          <tbody>
            {map(detail_checked, (list, idx) => {
				console.log(list)
              return (
                <tr key={`${idx}`}>
                  <td style={{ textAlign: "left" }}>{list.invoices_id}</td>
                  <td style={{ textAlign: "right" }}>
                    <CurrencyFormat
                      input="number"
                      prefix={"Rp. "}
                      readOnly
                      value={list.nominal}
                      maxLength="25"
                      thousandSeparator={true}
                      style={{ width: "100%" }}
                      displayType={"text"}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="row">
          <div className="col-md-12">
            <Button block onClick={() => this.openModalMethod()}>
              Lanjut
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderModalContentMethod() {
    const { filters, listbank } = this.state;
    const { jenis } = filters;
    
    const h2h = [{
      name:'H2H Mandiri', pg_code: 'mandiri_h2h', admin_fee: 0
    }] ;

    const listh2h = h2h.concat(listbank);

    this.setState((prevState) => ({
      form: {
        value: {
          ...prevState.form.value,
          pg_code: "",
          bank_name: "",
          admin_fee: 0,
        },
      },
    }));

    let ket = "";

    return (
      <div>
        <hr style={{ margin: "unset" }}></hr>
        <br></br>

        <form
          className="form"
          style={{ backgroundColor: "#ccc", padding: "15px" }}
        >
          {map(listh2h, (list, idx) => (
            <div className="inputGroup" key={idx}>
              {(() => {
                if (list.pg_code == "mandiri_h2h") {
                  ket = "";
                } else if (list.admin_fee == 0 && list.admin_percent != 0) {
                  ket = " (Biaya admin: " + list.admin_percent + "%)";
                } else {
                  ket = " (Biaya admin: Rp. " + list.admin_fee + ")";
                }
              })()}
              <input
                id={"pg_code" + idx}
                name="pg_code"
                type="radio"
                value={list.pg_code}
                bank_name={list.name}
                admin_fee={list.admin_fee}
                admin_percent={list.admin_percent}
                onChange={this._onFormChange}
              />

              <label htmlFor={"pg_code" + idx}>{list.name + ket}</label>
            </div>
          ))}
        </form>
        <br></br>

        <div className="row">
          <div className="col-md-6" style={{ paddingBlock: "2px" }}>
            <Button block onClick={() => this.openModalRincian()}>
              Kembali
            </Button>
          </div>
          <div className="col-md-6" style={{ paddingBlock: "2px" }}>
            <Button block onClick={() => this.onSendPayment()}>
              Lanjut
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderModalContentDetail() {
    const { user } = this.props;
    const { form, payload } = this.state;
    const { total, admin_fee, subtotal, bank_name, detail_checked } = form.value;
    let va_h2h=""
    if (!isEmpty(detail_checked)) {
      va_h2h = detail_checked[0].temps_id+detail_checked[0].periode
    }

    return (
      <div>
        <br></br>
        <form className="form">
          <img style={{ width: "70px" }} alt="logo" src={Logo} />
          <h3 style={{ margin: "unset" }}>Yayasan Pangudi Luhur</h3>
          <br></br>
          <h4 style={{ margin: "unset", color: "#3c8dbc" }}>
            Detail Transaksi Pemesanan
          </h4>
          <br></br>
          <div style={{ textAlign: "left" }}>
            <h5 style={{ margin: "unset" }}>Pembayaran Via: </h5>
            <br></br>
            <h4 style={{ margin: "unset", textAlign: "center" }}>
              {bank_name}
            </h4>
            <br></br>
          </div>
          <Table striped bordered hover size="sm">
            <tbody>
              {isEmpty(payload) ? (
                <>
                <tr>
                  <td style={{ textAlign: "left" }}>No Virtual Account</td>
                  <td style={{ textAlign: "right" }}>
                    {va_h2h}
                    <CopyToClipboard text={va_h2h}>
                      <span className="copytoclipboard" onClick={() => alert("berhasil disalin!")}>
                        <i className="fa fa-copy"></i>
                      </span>
                    </CopyToClipboard>
                  </td>
                </tr>
                </>
              ) : (
                <>
                <tr>
                  <td style={{ textAlign: "left" }}>No Virtual Account</td>
                  <td style={{ textAlign: "right" }}>
                    {payload.trx_id}
                    <CopyToClipboard text={payload.trx_id}>
                      <span className="copytoclipboard" onClick={() => alert("berhasil disalin!")}>
                        <i className="fa fa-copy"></i>
                      </span>
                    </CopyToClipboard>
                  </td>
                </tr>
                </>
              )}
              <tr>
                <td style={{ textAlign: "left" }}>No Order</td>
                <td style={{ textAlign: "right" }}>{payload.bill_no}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Subtotal</td>
                <td style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={subtotal}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Biaya Admin</td>
                <td style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={admin_fee}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left" }}>Total Pembayaran</th>
                <th style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={total}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </th>
              </tr>
            </tbody>
          </Table>
          <br></br>
          <div className="row">
            <div className="col-md-12">
              <Button block onClick={() => this.refreshPayments()}>
                Tutup
              </Button>
            </div>
          </div>
        </form>
        <br></br>
      </div>
    );
  }

  renderModalContentShowDetail(data) {
    const { user } = this.props;
    return (
      <div>
        <br></br>
        <form className="form">
          <img style={{ width: "70px" }} alt="logo" src={Logo} />
          <h3 style={{ margin: "unset" }}>Yayasan Pangudi Luhur</h3>
          <br></br>
          <h4 style={{ margin: "unset", color: "#3c8dbc" }}>
            Detail Transaksi Pemesanan
          </h4>
          <br></br>
          <div style={{ textAlign: "left" }}>
            <h5 style={{ margin: "unset" }}>Pembayaran Via: </h5>
            <br></br>
            <h4 style={{ margin: "unset", textAlign: "center" }}>
              {data.bank_name}
            </h4>
            <br></br>
          </div>
          <Table striped bordered hover size="sm">
            <tbody>
              <tr>
                <td style={{ textAlign: "left" }}>No Virtual Account</td>
                <td style={{ textAlign: "right" }}>
                  {data.trx_id}
                  <CopyToClipboard text={data.trx_id}>
                    <span className="copytoclipboard" onClick={() => alert("berhasil disalin!")}>
                      <i className="fa fa-copy"></i>
                    </span>
                  </CopyToClipboard>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>No Order</td>
                <td style={{ textAlign: "right" }}>{data.faspay_id}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Subtotal</td>
                <td style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={data.total - data.admin_fee}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Biaya Admin</td>
                <td style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={data.admin_fee}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "left" }}>Total Pembayaran</th>
                <th style={{ textAlign: "right" }}>
                  <CurrencyFormat
                    input="number"
                    prefix={"Rp. "}
                    readOnly
                    value={data.total}
                    maxLength="25"
                    thousandSeparator={true}
                    style={{ width: "100%" }}
                    displayType={"text"}
                  />
                </th>
              </tr>
            </tbody>
          </Table>
          <br></br>
        </form>
        <br></br>
        <div className="row">
          <div className="col-md-12" style={{ paddingBlock: "2px" }}>
            <Button block onClick={() => this.cancelPayments(data)}>
              Batalkan Pembayaran
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ paddingBlock: "2px" }}>
            <Button block onClick={() => MySwal.close()}>
              Tutup
            </Button>
          </div>
        </div>
        <br></br>
      </div>
    );
  }

  handleBack = () => {
    window.location.href = "/bayarpendaftaran";
  };

  render() {
    const { filters, form, listinvoice } = this.state;
    const { detail_checked } = form.value;

    form.value.subtotal = 0;
    map(
      detail_checked,
      (data, idx) =>
        (form.value.subtotal += Number(form.value.detail_checked[idx].nominal))
    );
    form.value.total = form.value.subtotal + form.value.admin_fee;

    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Bayar Pendaftaran</Breadcrumb.Item>
                  </Breadcrumb>

                  <Form onSubmit={this.onBayar}>
                    {/* <Form onSubmit={this.handleSubmit}> */}
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Nama Siswa</Form.Label>
                          <Form.Control
                            type="Text"
                            name="jalur"
                            value={localStorage.getItem("DATA_JALUR")}
                            disabled
                          />
                        </Form.Group>
                      </div>
                      {/* <div className="col-md-6">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Jenis Pembayaran</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={this.onDropdownSelected.bind(this)}
                            name="jenis"
                          >
                            <option value="">- Pilih Jenis Pembayaran -</option>
                            {this.createSelectItems()}
                          </Form.Control>
                        </Form.Group>
                      </div> */}
                    </div>
                    <div className="box-body">
                      <Form.Label style={{ fontSize: 20 }}>
                        Uang Pendaftaran
                      </Form.Label>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            {/* <th></th> */}
                            <th>No Invoice</th>
                            {/* <th>No VA H2H</th> */}
                            <th>Nominal</th>
                            {/* <th></th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {listinvoice.map((data, idx) => {
                            return (
                              <tr>
                                {/* <td>
                                  <input
                                    type="checkbox"
                                    name="status"
                                    data-input-array={true}
                                    data-array-position={idx}
                                    data-field-name="listinvoice"
                                    onChange={this._onFormChange}
                                    checked={detail_checked[idx] ? true : ""}
                                    value={data.status}
                                  />
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {data.invoices_id}
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  {data.no_va_h2h}
                                </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <CurrencyFormat
                                    input="number"
                                    prefix={"Rp. "}
                                    readOnly
                                    value={data.nominal}
                                    maxLength="25"
                                    thousandSeparator={true}
                                    style={{ width: "100%" }}
                                    displayType={"text"}
                                  />
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                                  {(data.faspay_id !== null ) ? (
                                    <Button block onClick={() => this.onShowDetail(data)}>
                                      Lihat Tagihan
                                    </Button>
                                  ) : (
                                    <Button block type="submit">
                                      Bayar
                                    </Button>
                                  )}
                                </td> */}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                      <br></br>
                      {this.state.is_show && (
                        <div className="row">
                          {/* <div className="col-md-7">
                            <p style={{ fontWeight: "bold" }}>
                              Total: &emsp; Rp. {form.value.subtotal}
                            </p>
                          </div> */}
                          <div
                            className="col-md-6"
                            style={{ paddingBlock: "2px" }}
                          >
                            <Button onClick={this.handleBack} block>
                              Kembali
                            </Button>
                          </div>
                          <div
                            className="col-md-6"
                            style={{ paddingBlock: "2px" }}
                          >
                            {!isEmpty(listinvoice) &&
                              (listinvoice[0].faspay_id !== null ? (
                                <Button
                                  block
                                  onClick={() =>
                                    this.onShowDetail(listinvoice[0])
                                  }
                                >
                                  Lihat Tagihan
                                </Button>
                              ) : (
                                <Button block type="submit">
                                  Bayar
                                </Button>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Bayar_pendaftaran_detail;
