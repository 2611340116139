import React, { Component } from 'react';
import {Breadcrumb, Button, Image, Table} from 'react-bootstrap';
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
const moment = require('moment');


class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        is_showdata : false,
        image_64 : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        console.log(this.state);
        
        let id = '';
        this.state.upload_documents[0].document_id===1? id = this.state.upload_documents[0].id : id = '';
        
        if(id!==''){
          fetch(global.variable.LINK_API + 'document/show/'+id,
          {
            method: 'get',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'),
            },
            //body: JSON.stringify(this.state), 
          })
          .then(response =>  response.json())
          .then(resData => {
              console.log(JSON.stringify(resData));
            if(resData.message==="Success"){
              this.setState({image_64 : resData.data[0].link});
              this.setState({is_show : true});
            }else{
              let msgerr = "";
              if(resData.errors){
                let count = Object.keys(resData.errors).length;
                for(let i=0; i<count; i++){
                  msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
                }
              }
              global.swal(msgerr)
            }
            
          })
        }
      }
    })
    fetch(global.variable.LINK_API + 'uniform/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
     
      //console.log(resData);
      console.log(this.state.uniform);
      console.log(localStorage.getItem('uniform1'));
       
    })

    
  }

  Total(){
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }

  BordirNama(){
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        if(this.state.uniform_data[i].name){
            return(this.state.uniform_data[i].name)
        }
    }
  }

  render() {
    
    let counter = 0;
    
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center' ,textAlign: 'center',}}>
                <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{width:800}}/>
            </div>
            <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>BUKTI PEMESANAN SERAGAM</label>
            </div>

            <div className="rowHorizontal">
              <div style={{width:160, height:160, border: '1px solid black', margin:20, marginLeft:50}}>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:15, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>No Pendaftaran</label>
                </div>
                <hr style={{border:'none', height:1, backgroundColor:'black'}}/>
                <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:20, textAlign: 'center', textDecorationLine: 'underline'}}>
                  <label>{this.state.year + '-' + this.state.registration_type_id + '-' + this.state.registration_number}</label>
                </div>
              </div>


              <div style={{width:120, height:160, border: '1px solid black', margin:20, marginLeft: 400}}>
                  {this.state.image_64?
                  <Image alt="Foto" style={{width: '100%', height: '100%'}} src={this.state.image_64} thumbnail />
                  :''}
              </div>
            </div>

            
            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Nama</label>
              </div>
              <div style={{margin:5}}>
                <label style={{textDecorationLine:'underline'}}>: {this.state.fullname}</label>
              </div>
            </div>

            <div className="rowHorizontal">
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>SD Asal</label>
              </div>
              <div style={{margin:5}}>
                <label style={{textDecorationLine:'underline'}}>: {this.state.school_from_name}</label>
              </div>
            </div>
            <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:100, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="50" style={{textAlign: "center", fontSize:12}}>No. </th>
                        <th width="150" style={{textAlign: "center", fontSize:12}}>Nama Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Harga Satuan</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Ukuran</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.is_showdata?
                       
                       
                        this.state.uniform.map((item, idx) => {
                        let showitem = [];
                        let price = 0;
                        let qty = 0;
                        let size ='-';
                        let total = 0;
                        
                        counter++;
                        //console.log(this.state.uniform_data.length)

                        for (let i = 0; i < this.state.uniform_data.length; i++) {
                           
                        // this.state.uniform_data.map((item2, idx2) => {
                            
                            //  console.log(this.state.uniform_data[i].price)
                            if(this.state.uniform_data[i].uniform_id == item.id){
                                price = this.state.uniform_data[i].price;
                                qty = this.state.uniform_data[i].qty;
                                size = this.state.uniform_data[i].size;
                                total = this.state.uniform_data[i].qty*this.state.uniform_data[i].price;

                               
                            }
                            else{
                                // console.log("tidak masuk if")

                            }
                           

                           
                        }
                        return (
                            <tr key={item.id}>
                                <td style={{textAlign: "center"}}>{counter}</td>
                                <td>{item.name}</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={price}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                                <td style={{textAlign: "center"}}>{qty}</td>
                                <td style={{textAlign: "center"}}>{size}</td>  
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={total}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                            </tr>
                            
                        )
                      
                       
                        
                        
                        })
                    :''}
                      </tbody>
                     
                            <tr>
                                
                                <td colSpan="5" style={{textAlign: "center", fontSize:12}}>Total</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.Total()}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                    </td>
                            </tr>
                      
                   
                    </table>
                  </div>
            </div>
            
                <div style={{width:400, margin:5, marginLeft:50}}>
                <label>Nama Bordir (wajib diisi maksimal 12 karakter)</label>
              </div>
              <div style={{margin:5, marginLeft:50}}>
                <label style={{textDecorationLine:'underline'}}>: {this.BordirNama()}</label>
              </div>

            <div className="rowHorizontal">
              <div style={{ margin:5, marginLeft:600}}>
                <label>Semarang, {moment().format('DD-MM-YYYY')}</label>
              </div>
            </div>
            <div className="rowHorizontal">
                <div style={{ margin:5, marginLeft:550, fontSize:14 }}>
                    <label >Panitia PPDB Tahun {this.state.tahun_ajaran_baru}</label>
                </div>
            </div>
            <div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Seragam Pramuka Penggalang dan 
                    atributnya dapat dibeli secara langsung / offline di Koperasi SMP PL Domenico Savio atau 
                    di toko seragam terdekat atau tetap menggunakan seragam pramuka penggalang Sekolah Dasar</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{fontStyle:'italic'}}>* Tunjukan</label> <label style={{textDecorationLine: 'underline', fontStyle:'italic'}}>Bukti Pemesanan Seragam </label> <label style={{fontStyle:'italic'}}> ini beserta</label> <label style={{textDecorationLine: 'underline', fontStyle:'italic'}}>Bukti Pembayaran Seragam </label> <label style={{ fontStyle:'italic'}}> pada saat pengambilan seragam.</label>
                    
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Seragam akan diterimakan pada Masa Pengenalan Lingkungan Sekolah (MPLS).</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Psikotest Online dilaksanakan pada 11 Februari 2023, jam 08.00. (gratis).</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Psikotest Online wajib diikuti oleh semua calon siswa.</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Informasi lebih lengkap tentang Psikotest Online dapat diakses di website sekolah (https://infoppdb.kliksekolah.com) mulai 4 Februari 2023.</label>
                </div>
                {/* <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Calon siswa membawa print out kartu pendaftaran atau kartu pelajar SD Asal</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Daftar ruangan psikotest bisa dilihat di papan pengumuman dan website SMP PL Domenico Savio mulai Kamis, 27 Feb 2020</label>
                </div>
                <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Calon siswa membawa alat tulis pensil, penghapus dan bolpoin.</label>
                </div> */}
                {/* <div style={{margin:5, marginLeft:50, marginTop:-5}}>
                    <label style={{ fontStyle:'italic'}}>* Calon siswa sdh makan dan jaga kesehatan supaya hasilnya maksimal.</label>
                </div> */}


            </div>

        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Cetak Bukti Pemesanan Seragam</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;