import React, { Component } from 'react';
import {Breadcrumb, Button, Image, Table, Form} from 'react-bootstrap';
import 'moment/locale/id.js';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
const moment = require('moment');


class ComponentToPrint extends React.Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        is_showdata : false,
        image_64 : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('student_id')});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('student_id'),
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState( resData.data[0]);
        // this.setState({quest : resData.data});
        this.setState({is_showdata : true});
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }

      console.log(this.state.questionnaire);
       
    })

    fetch(global.variable.LINK_API + 'uniform/'+ localStorage.getItem('student_id'),
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
     
      //console.log(resData);
      console.log(this.state.uniform);
      console.log(localStorage.getItem('uniform1'));
       
    })
    
  }

  Total(){
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }

  BordirNama(){
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        if(this.state.uniform_data[i].name){
            return(this.state.uniform_data[i].name)
        }
    }
  }

  render() {
    
    let counter = 0;
    
    return (

      this.state.is_show?
        <div id="divPrint" style={{width:810, border: '0px solid black'}}>
           
            
            <div className="rowHorizontal" style={{margin:50, marginBottom:10}}>
              <div style={{width:200, margin:5, marginLeft:50}}>
                <label>Nama</label>
              </div>
              <div style={{marginLeft:5}}>
                <label style={{textDecorationLine:'underline'}}>: {localStorage.getItem('student_id') +'-'+ this.state.fullname}</label>
              </div>
            </div>

                       

            <div className="rowHorizontal" >
              <div style={{margin:5, marginLeft:100, fontSize:11}}>  
              <table border="1">
                <thead>
                      <tr >
                        <th width="50" style={{textAlign: "center", fontSize:12}}>No. </th>
                        <th width="150" style={{textAlign: "center", fontSize:12}}>Nama Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Harga Satuan</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Barang</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Ukuran</th>
                        <th width="100" style={{textAlign: "center", fontSize:12}}>Jumlah Harga</th>
                      </tr>
                    </thead>
                    <tbody>
                        {this.state.is_showdata && this.state.uniform?
                       
                       
                        this.state.uniform.map((item, idx) => {
                        let showitem = [];
                        let price = 0;
                        let qty = 0;
                        let size ='-';
                        let total = 0;
                        
                        counter++;
                        //console.log(this.state.uniform_data.length)

                        for (let i = 0; i < this.state.uniform_data.length; i++) {
                           
                        // this.state.uniform_data.map((item2, idx2) => {
                            
                            //  console.log(this.state.uniform_data[i].price)
                            if(this.state.uniform_data[i].uniform_id == item.id){
                                price = this.state.uniform_data[i].price;
                                qty = this.state.uniform_data[i].qty;
                                size = this.state.uniform_data[i].size;
                                total = this.state.uniform_data[i].qty*this.state.uniform_data[i].price;

                               
                            }
                            else{
                                // console.log("tidak masuk if")

                            }
                           

                           
                        }
                        return (
                            <tr key={item.id}>
                                <td style={{textAlign: "center"}}>{counter}</td>
                                <td>{item.name}</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={price}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                                <td style={{textAlign: "center"}}>{qty}</td>
                                <td style={{textAlign: "center"}}>{size}</td>  
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={total}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                </td>
                            </tr>
                            
                        )
                      
                       
                        
                        
                        })
                    :''}
                      </tbody>
                     
                            <tr>
                                
                                <td colSpan="5" style={{textAlign: "center", fontSize:12}}>Total</td>
                                <td>
                                <CurrencyFormat
                                    input="number"
                                    prefix={'Rp. '}
                                    readOnly
                                    value={this.Total()}
                                    maxLength="25" thousandSeparator={true}
                                    style={{ width: '100%' }} displayType={'text'} />
                                    </td>
                            </tr>
                      
                   
                    </table>
                  </div>
            </div>
            <div className="rowHorizontal" style={{margin:50, marginTop:10}}>
              <div style={{width:200, margin:5, marginLeft:50}}>       
                <label>Nama Bordir </label>
                </div>   
                <div style={{marginLeft:5}}>
                <label style={{textDecorationLine:'underline'}}>: {this.BordirNama()}</label>
         </div>
         </div>
        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  
  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                        <div className="divContainter">
                          <Breadcrumb>
                            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Report Pemesanan Seragam Detail</Breadcrumb.Item>
                          </Breadcrumb>

                          <div>
                            <ReactToPrint
                              trigger={() =>  <Button style={{marginBottom:10}} block>Print</Button>}
                              content={() => this.componentRef}
                            />
                            <ComponentToPrint ref={el => (this.componentRef = el)} />
                          </div>

                          
                        </div>
                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Cetak;