import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';

class Upload_dokumen extends Component {

  constructor(props){
    super(props);

    this.state={
        CB_STUDYYEAR: '',
        CB_JALUR : '',
        val_flow : '',
        val_studyyear : '',
    }
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + 'uniform/flow/3',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));
       
        this.setState({CB_JALUR : resData.data});

      }

                     
      console.log(resData);
       
    })

    fetch(global.variable.LINK_API + 'parameter',
    {
      method: 'get',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        localStorage.setItem('DATA_PARAMETER', JSON.stringify(resData.data));
        this.setState({ CB_STUDYYEAR: resData.data.prmStudyYear});
      }
      console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')));
       
    })

    fetch(global.variable.LINK_API + 'report/uniform',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      //body: JSON.stringify(data)
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState({uniform : resData.data});
        this.setState({is_showdata : true});
        
      }
      console.log(this.state.uniform)
    })
  }

  createSelectItems() {
    let items = [];         
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {             
         items.push(<option key={i} value={this.state.CB_JALUR[i].id+'-'+this.state.CB_JALUR[i].fullname}>{this.state.CB_JALUR[i].id+' - '+this.state.CB_JALUR[i].fullname}</option>);   
    }
    return items;
  }

  createSelectItemsStudyYear() {
    let items = [];         
    for (let i = 0; i < this.state.CB_STUDYYEAR.length; i++) {             
      items.push(<option key={i} value={this.state.CB_STUDYYEAR[i].id}>{this.state.CB_STUDYYEAR[i].name}</option>);   
    }
    return items;
  }

  onDropdownSelected(e) {
    console.log("THE VAL", e.target.value);
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();
    localStorage.setItem('DATA_FLOW', this.state.val_flow);
    localStorage.setItem('DATA_YEAR', this.state.val_studyyear);
    
    
    if(this.state.val_flow===""){
      global.swal("Info", "Harap pilih status", "info"); 
      global.swal("Info", "Harap pilih status", "info"); 
      global.swal("Info", "Harap pilih status", "info"); 
    }else{
      window.location.href="/reportseragamdetail";
    }
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">

                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Report Pemesanan Seragam</Breadcrumb.Item>
                        </Breadcrumb>

                        <form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Tahun</Form.Label>
                            <Form.Control as="select" name="val_studyyear" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Semua Tahun -</option>
                              {this.createSelectItemsStudyYear()}
                              
                            </Form.Control>
                          </Form.Group>

                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Pilih Status</Form.Label>
                            <Form.Control as="select" name="val_flow" onChange={this.onDropdownSelected.bind(this)}>
                              <option value="">- Pilih Status -</option>
                              <option value="1">Sudah Pesan Seragam</option>
                              <option value="2">Sudah Upload Bukti Bayar Seragam</option>
                              <option value="3">Sudah Diverifikasi</option>
                              
                            </Form.Control>
                          </Form.Group>

                          <Button
                            block
                            type="submit">
                            Submit
                          </Button>
                        </form>
                      </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Upload_dokumen;