import React, { Component } from 'react';
import { Form, Breadcrumb, Table, Button } from 'react-bootstrap';
import ReactExport from "react-export-excel";
import 'moment/locale/id.js';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');


class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
      is_show: false,
      is_showdata: false,
      isChecked : false,
      option: '',
      data: [],
      show32: false,
      show33: false,
      
    }
  }

 

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});

   
    fetch(global.variable.LINK_API + 'uniform/questionnaire',
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState({ questionnaire: resData.data });
          this.setState({ is_showdata: true });
          // let tempData = resData.data.map((item, idx) => {
          //   return {student_id:localStorage.getItem('DATA_JALUR').split('-')[0], question_id:idx+1, option_id: 1, other: ""}
          // })
          // this.setState({data: tempData}, ()=> console.log(this.state.data))
        }

        //console.log(resData);
        console.log(this.state.questionnaire);


      })

  }

  handleSubmit = e => {
    e.preventDefault()
    // let dataArray = [];

    const data = { student_id: this.state.student_id, data: JSON.stringify(this.state.data) }
    console.log(data)
    if(this.state.data.length===35){
    console.log(this.state.data.length)

      return fetch(global.variable.LINK_API + 'uniform/questionnaire/insert',
        {
          method: 'post',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + global.cookies.get('access_token'),
          },
          body: JSON.stringify(data),
        })

        .then(response => response.json())
        .then(resData => {
          console.log(resData);


          if (resData.message === "Success") {

            global.swal("Success", resData.message, "success", )
        .then((value) => {
          window.location.href="/angket";
        });
      }else{
        global.swal("Error", resData.error, "info")
      }
    })
    .catch(err => {
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });
  }
  else{ global.swal("Error", "Data belum terisi lengkap.", "Error")}
    

  }


  handleChecked= param => e => {
    let data=[];
    let item =[];
    let items =  Object.create({});

    items.student_id = this.state.student_id;
    items.question_id = param;
    items.option_id = e.target.value;
    items.other = "";

    this.state.data[param-1] = items;

    // this.setState({ data : [...this.state.data, items]}, () => {
    //   console.log(this.state)

    // });
    console.log(this.state)


    
  }



  
 ChangeCheck(param) {
    let items = [];

    // console.log("in fn")
    //console.log(param)
    // console.log(param.length)
    for (let i = 0; i < param.length; i++) {
      items.push(<option key={i} value={param[i].id}>{param[i].name}</option>)

    }

    return items;
  }

  createRadio = (item, idx) => {
    let radio = []
    idx++;
    for (let i = 0; i <= 4; i++) {
      
    //console.log(item.option[i])
      radio.push(<td> <input type="radio"
      // key={idx}
      
      name={'ischecked'+ idx}
      onChange={this.handleChecked(idx)}
      value={item.option[i].id}
      /></td>)
    }
    return radio
  }



  looping= (item, idx) => {
    let items =[]
    for(let i =0; i<=31 ; i++){
      items.push(
        item.id == i ? this.createRadio(item, idx) : ''
        )
      
    }
    return items
  }
  

//   test(e){
//     let test = ''
//     this.setState({ [e.target.name]: e.target.value });
//         if(e.target.value === 17){
//       test.push('test')
      

//     }
// return test
//   }

onChange32 (e){
   this.setState({ text32: e.target.value })
  let items =  Object.create({});

    items.student_id = this.state.student_id;
    items.question_id = 32;
    items.option_id = "17";
    items.other = e.target.value;

    this.state.data[31] = items;
    console.log(this.state)
} 

onChange33 (e){
  this.setState({ text33: e.target.value })
 let items =  Object.create({});

   items.student_id = this.state.student_id;
   items.question_id = 33;
   items.option_id = "25";
   items.other = e.target.value;

   this.state.data[32] = items;
   console.log(this.state)
} 

onChange34 (e){
  this.setState({ text34: e.target.value })
 let items =  Object.create({});

   items.student_id = this.state.student_id;
   items.question_id = 34;
   items.option_id = "26";
   items.other = e.target.value;

   this.state.data[33] = items;
   console.log(this.state)
} 

onChange35 (e){
  this.setState({ text35: e.target.value })
 let items =  Object.create({});

   items.student_id = this.state.student_id;
   items.question_id = 35;
   items.option_id = "26";
   items.other = e.target.value;

   this.state.data[34] = items;
   console.log(this.state.data)
} 


  onDropdownSelected(e) {
    
    console.log("THE VAL" + e.target.value);
    this.setState({ [e.target.name]: e.target.value });


    let items =  Object.create({});

    items.student_id = this.state.student_id;
    items.question_id = 32;
    items.option_id = e.target.value;
    items.other = "";

    this.state.data[31] = items;



    if(e.target.value == 17){
      this.setState({ show32: true });
    }
    else{this.state.show32=false}
    // this.setState({quest : e.target.value})
    console.log(this.state)

  }

  onDropdownSelected2(e) {
    console.log("THE VAL* " + e.target.value);
    this.setState({ [e.target.name]: e.target.value });

    let items =  Object.create({});

    items.student_id = this.state.student_id;
    items.question_id = 33;
    items.option_id = e.target.value;
    items.other = "";

    this.state.data[32] = items;


   if(e.target.value == 25){
     this.setState({show33: true})
   }
   else{this.state.show33=false}
   console.log(this.state)
  }



  createSelectItems(param) {
    let items = [];

    // console.log("in fn")
     console.log(param)
    // console.log(param.length)
    for (let i = 0; i < param.length; i++) {
      items.push(<option key={i} value={param[i].id}>{param[i].name}</option>)
    }
    


    // param.map((item, idx) =>{
    //     items.push(<option key={idx} value={item.id}>{item.name}</option>)
    // });

    // Object.keys(param).map((item,idx)=>{

    //     items.push(<option key={idx} value={item.id}>{item.name}</option>)
    // })
    return items;
  }
  handleClick = param => e => {
    e.preventDefault();
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank');

  }

  render() {

    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Angket Detail</Breadcrumb.Item>
                  </Breadcrumb>

                  <div className="box-body">
                    <div className="row">
                      {/* <Form onSubmit={this.handleSubmit}> */}
                      <Table striped bordered hover size="sm">

                        <thead>
                          {this.state.is_showdata ?


                            //   return(
                            <tr>
                              <th>No</th>
                              <th>Pernyataan</th>
                              <th>Sangat Tidak Setuju</th>
                              <th>Tidak Setuju</th>
                              <th>Ragu-ragu</th>
                              <th>Setuju</th>
                              <th>Sangat Setuju</th>

                            </tr>
                            //   )
                            //  })
                            : ''}
                        </thead>

                        <tbody>

                        
                          {this.state.is_showdata ?
                            this.state.questionnaire.map((item, idx) => {
                              counter++;
                            
                              return (
                                <tr>
                                  <td>{counter}</td>
                                  <td >{item.name}</td>
                                  
                                  {this.looping(item, idx)}
                                  {/* {item.id == 2 ? this.createRadio(item, idx) : ''}
                                  {item.id == 3 ? this.createRadio(item, idx) : ''}
                                  {item.id == 4 ? this.createRadio(item, idx) : ''}
                                  {item.id == 5 ? this.createRadio(item, idx) : ''}
                                  {item.id == 6 ? this.createRadio(item, idx) : ''} */}


                                 

                                  {item.id === 32 ?
                                    <td colSpan='3'>
                                      
                                      <Form.Control as="select" onChange={this.onDropdownSelected.bind(this)}>
                                        <option value="">- Pilih Opsi -</option>
                                        {
                                          this.createSelectItems(item.option)

                                        }
                                        

                                      </Form.Control></td> : ''
                                  }
                                  
                                  {item.id === 32 && this.state.show32 ?
                                    <td colSpan='2'>
                                      <Form.Control
                                        type="Text"
                                        name="text32"
                                        onChange={this.onChange32.bind(this)}
                                        value={this.state.text32}
                                      /></td> : ''}     
                                  {item.id === 33 ?
                                    <td colSpan='3'>
                                      <Form.Control as="select" onChange={this.onDropdownSelected2.bind(this)}>
                                        <option value="">- Pilih Opsi -</option>
                                        {
                                          this.createSelectItems(item.option)
                                        }

                                      </Form.Control></td> : ''
                                  }
                                   {item.id === 33 && this.state.show33 ?
                                    <td colSpan='2'>
                                      <Form.Control
                                        type="Text"
                                        name="text33"
                                        onChange={this.onChange33.bind(this)}
                                        value={this.state.text33}
                                      /></td> : ''}     
                                  {item.id === 34 ?
                                    <td colSpan='5'>
                                      <Form.Control
                                        type="Text"
                                        name="text34"
                                        onChange={this.onChange34.bind(this)}
                                        value={this.state.text34}
                                      /></td> : ''}
                                    
                                  {item.id === 35  ?
                                    <td colSpan='5'>
                                      <Form.Control
                                        type="Text"
                                        name="text35"
                                        onChange={this.onChange35.bind(this)}
                                        value={this.state.text35}
                                      /></td> : ''}

                                </tr>
                              )
                            })
                            : ''}
                        </tbody>
                      </Table>
                     
                      <Form.Group>
                        <div className='rowHorizontal'>
                          <Button onClick = {this.handleSubmit}type="submit" block style={{ margin: 5, height: 50 }} >Submit</Button>
                        </div>
                      </Form.Group>
                      {/* </Form> */}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;