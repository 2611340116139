import React, { Component } from 'react';
import { Breadcrumb, Table, Button } from 'react-bootstrap';
import ReactExport from "react-export-excel";
import CurrencyFormat from 'react-currency-format';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const moment = require('moment');

class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data_student: '',
      is_show: false,
      data: '',
      totalUpp: 0,
      totalSpp : 0,
      rataUpp:0,
      rataSpp:0
    }
  }

  handleClick = param => e => {
    e.preventDefault();
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank');

  }

  componentDidMount() {


    let data_report = JSON.parse(localStorage.getItem('data_report'));
    console.log(data_report)


    let data = []
    let total = 0;
    let total2 = 0;
    let rata = 0;
    let rata2 = 0;


   

    data_report.map((item, idx) => {
      let items = Object.create({});


      items.fullname = item.fullname;
      items.pendaftaran = item.year + '-' + item.registration_type_id + '-' + item.registration_number;
      items.hp = item.hp;
      items.metode = item.interview_method ?
        item.interview_method.name : '';
      items.whatsapp = item.whatsapp ?
        item.whatsapp : '';

      items.jadwal = item.schedule[0] && item.schedule[0].schedule_interview_at?
        moment(item.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'):'';


      data.push(items);
    })
    


    this.setState({ report: data ,totalUpp: total, totalSpp: total2, rataUpp: rata, rataSpp: rata2}, () => { this.setState({ is_show: true }) })
    
  }

  render() {
    let data_report = JSON.parse(localStorage.getItem('data_report'));
    let counter = 0;
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Report Jadwal Wawancara</Breadcrumb.Item>
                  </Breadcrumb>
                  {this.state.is_show ?
                    <ExcelFile element={<Button block style={{ margin: 5, height: 50 }}>Download Data</Button>}>
                      <ExcelSheet data={this.state.report} name="Report Wawancara">
                        <ExcelColumn label="Nama Lengkap" value="fullname" />
                        <ExcelColumn label="Nomor Pendaftaran" value="pendaftaran" />
                        <ExcelColumn label="Nomor Handphone" value="hp" />
                        <ExcelColumn label="Metode" value="metode" />
                        <ExcelColumn label="Whatsapp" value="whatsapp" />
                        <ExcelColumn label="Jadwal Wawancara" value="jadwal" />
                       
                      </ExcelSheet>
                    </ExcelFile>
                    : ''}

                  <div className="box-body">
                    <div className="row">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Nama Lengkap (Nama dapat diklik)</th>
                            <th>Nomor Pendaftaran</th>
                            <th>Nomor Handphone</th>
                            <th>Metode</th>
                            <th>Nomor Whatsapp</th>
                            <th>Jadwal Wawancara</th>
                            
                          </tr>
                        </thead>
                        <tbody>


                          {data_report.map((item, idx) => {
                            counter++;
                            return (
                              <tr>
                                <td>{counter}</td>
                                <td style={{ color: 'blue' }} onClick={this.handleClick(item.id)}>{item.fullname}</td>
                                <td>{item.year + '-' + item.registration_type_id + '-' + item.registration_number}</td>
                                <td>{item.hp}</td>

                                <td>{item.interview_method ?
                                  item.interview_method.name : ''}</td>
                                <td>{item.whatsapp}</td>
                                <td>{item.schedule[0] && item.schedule[0].schedule_interview_at?
                                       moment(item.schedule[0].schedule_interview_at, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm'):''}</td>
                               
                              </tr>
                            )
                          })}
                        </tbody>
                        
                      </Table>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;