import React, { Component } from 'react';
import {Form, Breadcrumb, Button, Row, Col} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import remSep from "string-remove-thousand-separators";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const moment = require('moment');

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        note_verification : '',

    }
  }

  getFiles(files){
    this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        if(this.state.upload_upp){
          let totalnominal = 0
          for(let i=0; i<this.state.upload_upp.length; i++){
            this.setState({['nominal'+ this.state.upload_upp[i].counter]: this.state.upload_upp[i].nominal})
            totalnominal += this.state.upload_upp[i].nominal;
          }
          this.setState({total:totalnominal})
        }

        let potongan2 = this.state.data_bayar[0].nominal * this.state.data_bayar[0].potongan / 100

        let nominal2 = this.state.data_bayar[0].nominal - potongan2;
                       this.setState({hasil:nominal2})
        let sisanominal = this.state.hasil - this.state.total;
                          this.setState({sisa:sisanominal})
        let presentase = (this.state.total/this.state.hasil*100).toFixed(2);
                         this.setState({presen:presentase})
        console.log(this.state);
      }
       
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onChangeNom = e => {
  this.setState({ [e.target.name]: remSep(e.target.value) })
  }
  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleSubmit = event => {
    event.preventDefault();
    //console.log("state 1 " + JSON.stringify(this.state));

    //console.log(this.state.encrypt_64);

    console.log(JSON.stringify(this.state));

  }

  handleBack = () => {
    window.location.href="/verifikasi";
  }

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open("/image/"+param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')
    
    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  
  form1(){
    return (
      <div>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jalur</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.registration_type.name}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Lengkap</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.fullname}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nomor Registrasi</Form.Label>
          <Form.Control 
            type="Text"
            name="nomor_registrasi"
            maxLength="100"
            value={ this.state.year + '-' + this.state.registration_type_id  + '-' +  this.state.registration_number}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>UPP</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.hasil} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Total nominal yang telah dibayar</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.total} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Sisa nominal yang belum dibayar</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.sisa} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Persentase yang telah dibayar</Form.Label>
          <Form>
            {/* <CurrencyFormat value={this.state.presen} style={{ width : '100%'}} thousandSeparator={true}  disabled /> */}
            <Progress
              percent={this.state.presen}
            />
          </Form>
        </Form.Group>
          <Form.Group></Form.Group>
      </div>
    );
  }

  

  formbukti(){
    
    let guardian = this.state.upload_upp;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Bukti {this.state.upload_upp[i].counter}</Form.Label>

        <div>
        {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}
        
        <Link  target="_blank" onClick={this.download(this.state.upload_upp[i].id)}>Lihat Bukti</Link>
                          <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Nominal</Form.Label>
                          
                           <div className='rowHorizontal'>
                           <Row>
                            <Col sm={8}>
                            <CurrencyFormat 
                              input="number"
                              name={"nominal"+this.state.upload_upp[i].counter} 
                              onChange={this.onChangeNom}
                              value={this.state['nominal'+this.state.upload_upp[i].counter]}
                              maxLength="25"  thousandSeparator={true}
                              style={{ height:30, width : '100%'}} />
                            </Col>
                           
        <Col sm={4}><Button onClick={this.handleVerifikasi(this.state.upload_upp[i].counter)} block style={{marginLeft: 20, height:30}} >Verifikasi</Button></Col>
        </Row>
        </div>
        
        </Form.Group>
        </div>
      </Form.Group>
      )
    }

    return showitem;
  }

  handleTest(){
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 4, note_verification : this.state.note_verification }

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        let m = moment();  
        m = moment(resData.data.schedule_test_at, 'YYYY-MM-DD').format('DD-MM-YYYY');
        global.swal("Success", 'Jadwal test adalah ' + m + '\n Pada pukul 13.00 – 15.00 WIB \nDi ruang ' +  resData.data.classroom_id, "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
  }

  handleVerifikasi = param => e => {
    e.preventDefault();
   
    
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], counter : param, nominal : this.state['nominal'+param]}
    fetch(global.variable.LINK_API + 'uploadbuktiupp/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Verifikasi Bukti Bayar UPP', "success")
        .then((value) => {
          window.location.href="/verifikasibuktiuppdetail";
        });
      }
       
    })

  }

  
  handleConfirm= event =>{
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 8}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Verifikasi Bukti Bayar UPP', "success")
        .then((value) => {
          window.location.href="/verifikasibuktiupp";
        });
      }
       
    })
  }

  handleTolak= event =>{

    global.swal({
      text: 'Apakah anda yakin akan ditolak?',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 99, note_reject : confirm}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'flow/update',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
    .catch(err => {
      console.log(err)
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });

    
    
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Verifikasi Bukti Bayar UPP</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          {this.state.is_show ? 
                          this.form1()
                           : ''}


                          {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Bukti Bayar UPP</Form.Label>
                          </div>
                          : ''}

                          {this.state.is_show ? 
                          this.formbukti()
                           : ''}



                          <Form.Group>
                          <div>
                            <Form.Label>*Klik tombol confirm setiap setelah verifikasi </Form.Label>
                          </div>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleConfirm} block style={{margin:5, height:50}} >Confirm</Button>
                          </div>

                          {this.state.presen && this.state.presen === 100?
                            <div>
                            <div className='rowHorizontal'>
                                <Button onClick={this.handleSubmit} type="submit" block style={{margin:5, height:50}} >Submit</Button>
                            </div>
                            
                            </div>:''}
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;