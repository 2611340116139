export default {
    //LINK_WS : 'http://127.0.0.1/project_lkp/lkp/public/api/',
    //LINK_API : 'https://api.ppdb.kliksekolah.com/public/api/',
    //LINK_API : 'https://api.ppdb.smppl1yogya.kliksekolah.com/public/api/', 
    //LINK_API : 'https://api.ppdb.smpplsedayu.kliksekolah.com/public/api/', 
    //LINK_API : 'http://localhost:8000/api/',
    //LINK_API : 'https://api.ppdb.kliksekolah.com/public/api/',
    LINK_API : 'https://api.dev.ppdb.kliksekolah.com/public/api/',
    //LINK_API : 'https://api.stage.ppdb.kliksekolah.com/api/',
	LINK_API_AKADEMIS : 'https://dev.api.academics.kliksekolah.com/',
	//LINK_API_AKADEMIS : 'https://academics.api.kliksekolah.com/',
};