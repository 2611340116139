import React, { Component } from "react";
import "./Login.css";
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()


export default class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
    };
  }

  validateForm() {
  }

  componentDidMount(){
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf('/') + 1);
    this.setState({token:id});

      try {
        fetch(global.variable.LINK_API + 'auth/signup/activate/'+id,
        {
          method: 'get',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
        })
        .then(response =>  response.json())
        .then(resData => {
          console.log(JSON.stringify(resData));
          if(resData.message==="Success"){
            global.swal("Success", 'Akun anda berhasil diaktifkan', "success")
            .then((value) => {
              window.location.href="/";
            });
            //global.swal(Cookies.get('access_token'));
          }else{
            let msgerr = "Gagal konfirmasi, token telah digunakan atau telah expired";
            if(resData.errors){
              let count = Object.keys(resData.errors).length;
              for(let i=0; i<count; i++){
                msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
              }
            }
            global.swal("Error", msgerr, "info")
            .then((value) => {
              window.location.href="/";
            });
          }
          
        })
        return true
      } catch (error) {
        global.swal(error);
        return false
      }
    
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    
    
  }

  back(){
    
    window.location.href="/";
  }

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          {/*<FormGroup controlId="user_id">
            <FormLabel style={{fontSize:18}}>Aktivasi Akun Anda</FormLabel>
          </FormGroup>
          
          <Button
            block
            style={{backgroundColor:'blue', color:'white', margin:10, width:100}}
            type="submit">
            Aktivasi
          </Button>*/}
          </form>
      </div>
    );
  }
}