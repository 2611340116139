import React, { Component } from "react";
import { Form, Breadcrumb, Button } from "react-bootstrap";

class Bayar_pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CB_JALUR: "",
      val_jalur: "",
    };
  }

  componentDidMount() {
    fetch(global.variable.LINK_API + "student/flow/2", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          //localStorage.setItem('DATA_SISWA', JSON.stringify(resData.data));

          this.setState({ CB_JALUR: resData.data });
        }
        console.log(resData);
      });

    fetch(global.variable.LINK_API + "parameter", {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      //body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          localStorage.setItem("DATA_PARAMETER", JSON.stringify(resData.data));
        }
        console.log(JSON.parse(localStorage.getItem("DATA_PARAMETER")));
      });
  }

  createSelectItems() {
    let items = [];
    for (let i = 0; i < this.state.CB_JALUR.length; i++) {
      items.push(
        <option
          key={i}
          value={
            this.state.CB_JALUR[i].id + "-" + this.state.CB_JALUR[i].fullname
          }
        >
          {this.state.CB_JALUR[i].id + " - " + this.state.CB_JALUR[i].fullname}
        </option>
      );
    }
    return items;
  }

  onDropdownSelected(e) {
    console.log("THE VAL", e.target.value);
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("DATA_JALUR", this.state.val_jalur);

    if (this.state.val_jalur === "") {
      global.swal("Info", "Harap pilih siswa", "info");
    } else {
      window.location.href = "/bayarpendaftarandetail";
    }
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Bayar Pendaftaran</Breadcrumb.Item>
                  </Breadcrumb>

                  <form onSubmit={this.handleSubmit}>
                    <Form.Group
                      controlId="exampleForm.ControlSelect1"
                      style={{ marginTop: 30 }}
                    >
                      <Form.Label>Bayar Pendaftaran</Form.Label>
                      <Form.Control
                        as="select"
                        name="val_jalur"
                        onChange={this.onDropdownSelected.bind(this)}
                      >
                        <option value="">- Pilih Siswa -</option>
                        {this.state.CB_JALUR ? this.createSelectItems() : ""}
                      </Form.Control>
                    </Form.Group>

                    <Button block type="submit">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Bayar_pendaftaran;
