import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        achieve_type_id : '',
        achieve_rank_id : '',
        achieve_type_id_name : '',
        achieve_rank_id_name : '',
        name : '',
        promoter : '',
        rank : '',
    }
  }

  componentDidMount() {
    /*if(localStorage.getItem('reg2')){
      this.setState(JSON.parse(localStorage.getItem('reg2')));
    }*/
  }

  createSelectAchieveType() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveType;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectAchieveRank() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmAchieveRank;
    for (let i = 0; i < params.length; i++) {             
      items.push(<option key={i} value={params[i].id+'_'+params[i].name}>{params[i].name}</option>);   
    }
    return items;
  }

  showRemove(){
    if(localStorage.getItem('reg6')){
      return(
        <Button onClick={this.handleClear} block style={{margin:5, height:30, width: 100}}>Clear Data</Button>
      );
    }
  }

  showPrestasi() {
    if(localStorage.getItem('reg6')){
      let guardian = JSON.parse(localStorage.getItem('reg6'));
      console.log(localStorage.getItem('reg6'));
      let showitem = [];

      for (let i = 0; i < guardian.length; i++) { 
        showitem.push(
          <div key={guardian[i].name}>
            <hr/>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Jenis </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_type_id_name} </Form.Label>
              
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama Prestasi </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Penyelenggara </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].promoter} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Tingkat </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_rank_id_name} </Form.Label>
            </div>
            <div className='rowHorizontal'>
              <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Peringkat </Form.Label>
              <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].rank} </Form.Label>
            </div>
          </div>
        );
      }

      return showitem;
    }
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value.split('_')[0], [e.target.name + "_name"]: e.target.value.split('_')[1]});
  }

  handleSubmit = event => {
    event.preventDefault();    
    
    window.location.href="/pendaftaran7";
    
  }

  handleAdd = () => {
    console.log("state 4 " + JSON.stringify(this.state));
    let item =[];
    if(localStorage.getItem('reg6')){
      item = JSON.parse(localStorage.getItem('reg6'));
    }
    item.push(this.state);

    
    console.log("score " + JSON.stringify(item));

    localStorage.setItem('reg6', JSON.stringify(item));
    
    window.location.href="/pendaftaran6";
  }

  handleClear = () =>{
    localStorage.removeItem('reg6');
    window.location.href="/pendaftaran6";
  }

  handleBack = () => {
    window.location.href="/pendaftaran5";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 6</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleAdd}>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Prestasi</Form.Label>

                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Jenis</Form.Label>
                            <Form.Control as="select" name="achieve_type_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.achieve_type_id + '_' + this.state.achieve_type_id_name} required>
                              <option value="">-Pilih Jenis-</option>
                              {this.createSelectAchieveType()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Prestasi</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="name"
                              maxLength="100"
                              value={this.state.name}
                              onChange={this.onChange} />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Penyelenggara</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="promoter"
                              maxLength="100"
                              value={this.state.promoter}
                              onChange={this.onChange}  />
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Tingkat</Form.Label>
                            <Form.Control as="select" name="achieve_rank_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.achieve_rank_id + '_' + this.state.achieve_rank_id_name} required>
                              <option value="">-Pilih Tingkat-</option>
                              {this.createSelectAchieveRank()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Peringkat</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="rank"
                              maxLength="5"
                              value={this.state.rank}
                              onChange={this.onChange}  />
                          </Form.Group>

                          <div className='rowHorizontal'>
                            <Button  type="submit" block style={{margin:5, height:50}}>Tambah</Button>
                          </div>
                          

                          {this.showRemove()}
                          {this.showPrestasi()}
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button onClick={this.handleSubmit} block style={{margin:5, height:50}} >Next</Button>
                          </div>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;