import React, { Component } from 'react';
import {Form, Breadcrumb, Button, Table, Image} from 'react-bootstrap';
import 'moment/locale/id.js';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        note : '',
        original_name : '',
        upload_file : '',
    }
  }


  handleUpload= event => {

    event.preventDefault();

    if(this.state.original_name ===''){
      global.swal("Error", "Harap pilih file gambar", "info")
    }else{

      global.swal({
        text: 'Apakah anda yakin akan diupload?',
        backgroundColor: 'blue',
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: [
          'Cancel',
          {text: 'Upload', 
          closeModal: false,}
        ],
      })
      .then((confirm) => {
        let errorMessage =  { code : 403, message : 'cancel' };
        if (!confirm) throw errorMessage;
        
        const data = new FormData()
        data.append('upload_file', this.state.selectedFile)
        data.append('student_id', localStorage.getItem('DATA_JALUR').split('-')[0])
        data.append('original_name', this.state.original_name)
        data.append('note', this.state.note)

        return fetch(global.variable.LINK_API + 'uniform/upload',
        {
          method: 'post',
          headers: {
              Accept: "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          body: data, 
        })
      })
      .then(results => {
        return results.json();
      })
      .then(resData => {
        if(resData.message==="Success"){
          console.log(resData);
          global.swal("Success", 'Sukses Upload', "success")
          .then((value) => {
            window.location.href="/uploadbuktiseragamdetail";
          });
        }
        
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if(err.code!==403){
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });

    }
  
  };

  getFiles(files){
    if(files[0].type==="image/jpeg" || files[0].type === "image/png"){
      if(files[0].size.split(' ')[0] > 2048){
        global.swal("Error", "Gambar tidak boleh lebih dari 2 MB", "info")
      }
      else{
        this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
      }
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png)", "info")
    }
  }

  onChangeHandler=event=>{
    var file = event.target.files[0];

    if(file.size>2000000){
      global.swal("Error", "File tidak boleh lebih dari 2 MB", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }else if(file.type==="image/png"||file.type==="image/jpeg"||file.type==="application/pdf"){
      console.log(file);
       this.setState({selectedFile: file, original_name : file.name});
    }else{
      global.swal("Error", "Harap pilih gambar berupa (*.jpg atau *.png) atau pdf", "info")
      this.setState({ selectedFile: '', original_name: '',  })
    }
 
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('student_id')});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('student_id'),
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState( resData.data[0]);
        // this.setState({quest : resData.data});
        this.setState({is_showdata : true});
        //this.setState({note_verification : resData.data[0].note_verification});
        this.setState({is_show : true});
        console.log(this.state);
      }

      console.log(this.state.questionnaire);
       
    })

    

    
  }
    
  

  runswal(){
    var span = document.createElement("span");
    //span.innerHTML = "Terima kasih anda telah mendaftar ke SMP PL Domenico Savio Semarang. \n\nNomor Pendaftaran anda adalah " + resData.data.year + '-' + resData.data.registration_type_id  + '-' +  resData.data.registration_number +"\n\n<strong>Silahkan melakukan transfer biaya pendaftaran sebesar Rp.100.000 ke rekening Bank Mandiri no 136-00-0149000-9 atas nama SMP PANGUDI LUHUR DOMENICO SAVIO SMG dengan mencantumkan nomor pendaftaran anda pada kolom catatan.</strong> \n\nData anda akan kami proses dalam waktu maksimal 24 jam setelah anda mengunggah bukti transfer.";
    span.innerHTML = this.state.info_swal;

    global.swal({title : "Success", /*"Kode Pembayaran anda adalah " + resData.data.year + resData.data.registration_type_id + resData.data.registration_number + "\n Nomor Rekening : 136-00-0149000-9 \na/n SMP Pangudi Luhur Domenico Savio SMG"*/
    icon: "success",
    
    content: span,})
    .then((value) => {
      window.location.href="/uploadbuktiseragam";
    });
  }

  
  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open(param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  formbukti(){
    return(
      <div>
      <Form.Group controlId="exampleForm.ControlInput1">
      <Form.Label>Catatan</Form.Label>
      <Form.Control 
        type="Text"
        name="note"
        maxLength="50"
        value={this.state.upload_uniform.note}
        onChange={this.onChange}  disabled/>
    </Form.Group>
       <Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_uniform.link} thumbnail />
       </div>
    )
}
  
  formupload(){
    let guardian = this.state.upload_uniform;
    let showitem = [];
    //console.log(guardian);

    
      showitem.push(
        
        <Form.Group controlId="exampleForm.ControlSelect1">
          <div>
         
        <Form.Label>Bukti</Form.Label>
       
        <div className='rowHorizontal'>
        {/* <Image alt="Dokumen" style={{width:400}} src={this.state.upload_uniform.link} thumbnail /> */}
        
        
         <Link  target="_blank" onClick={this.download(this.state.upload_uniform.link)}>Lihat Bukti</Link>
        
        
         </div>
        </div>
      </Form.Group>
      )

    return showitem;
  }


  Buttonsub(){
    let guardian = this.state.upload_uniform;
    let showitem = [];
    //console.log(guardian);
    let show = false;
      if (this.state.upload_uniform ){
        show =true}
      
    showitem.push(
      <Form.Group controlId="exampleForm.ControlSelect1">
      {show?
      <div>
        <div className='rowHorizontal'>
      
        <Button type="submit" block style={{margin:5, height:50}} >Submit</Button>
      </div>
      </div>
      :''}
      
    </Form.Group>
    )

    return showitem;
  }



  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


 
  handleBack = () => {
    window.location.href="/uploadbukti";
  }


  Total(){
    let totalnominal = 0;
    for (let i = 0; i < this.state.uniform_data.length; i++) {
        totalnominal +=  this.state.uniform_data[i].qty * this.state.uniform_data[i].price
    }
    console.log(totalnominal)
    return(totalnominal)
  }

  render() {
    let counter = 0;

    return (
        <div className="content-wrapper">
          <section className="content-header">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="divContainter">
                    <Breadcrumb>
                      <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                      <Breadcrumb.Item active>Angket Detail</Breadcrumb.Item>
                    </Breadcrumb>
                    <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('student_id') +'-'+ this.state.fullname}
                              disabled />
                          </Form.Group>
                    <div className="box-body">
                      <div className="row">
                        <Table striped bordered hover size="sm">
                          <thead>
                            {this.state.is_showdata ?
  
  
                              //   return(
                              <tr>
                                <th>No</th>
                                <th>Pernyataan</th>
                                <th>Jawaban</th>
                               
  
                              </tr>
                              //   )
                              //  })
                              : ''}
                          </thead>
  
                          <tbody>
  
                          
                            {this.state.is_showdata ?
                              this.state.questionnaire.map((item, idx) => {
                                counter++;
                              
                                return (
                                  <tr>
                                    <td>{counter}</td>
                                    <td>{item.question_name}</td>
                                    {item.option_name == "" || item.option_name === "Lainnya"?
                                    <td>{item.other}</td>:<td>{item.option_name}</td>
                                }
                                    
  
                                   
  
                                   
                                  </tr>
                                )
                              })
                              : ''}
                          </tbody>
                        </Table>
                       
                        
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
  }
}

export default Pendaftaran;