import React, { Component } from "react";
import {  FormGroup,  Image } from "react-bootstrap";
import Cookies from 'js-cookie'
import { trackPromise } from 'react-promise-tracker';

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()


export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: "",
      name: "",
      password: "",
      password_confirmation: "",
      enc64 : '',
    };
  }

 
  componentDidMount(){
    var url = window.location.pathname;
    var id = url.substring(url.lastIndexOf('/') + 1);
    trackPromise(
        fetch(global.variable.LINK_API + 'document/show/'+id,
        {
          method: 'get',
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer '+ global.cookies.get('access_token'),
          },
          //body: JSON.stringify(this.state), 
        })
        .then(response =>  response.json())
        .then(resData => {
            console.log(JSON.stringify(resData));
          if(resData.message==="Success"){
            this.setState({enc64 : resData.data[0].link});
            if(resData.data[0].link){
              window.location.href = resData.data[0].link;
            }
          }else{
            let msgerr = "";
            if(resData.errors){
              let count = Object.keys(resData.errors).length;
              for(let i=0; i<count; i++){
                msgerr = msgerr + resData.errors[Object.keys(resData.errors)[i]] + "\n";
              }
            }
            global.swal(msgerr)
          }
          
        })
    );
  }

  back(){
    
    window.location.href="/";
  }

  render() {
    return (
      <div >
        <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="user_id" >
            {this.state.enc64?
            <div>
            <Image alt="Bukti transfer" style={{width:600}} src={this.state.enc64} thumbnail />
            </div>
            : ''}      
        </FormGroup>
          

        </form>
      </div>
    );
  }
}