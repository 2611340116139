import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
    }
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
        console.log(this.state);
      }
       
    })
  }

  

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }


  handleSubmit = event => {
    event.preventDefault();
    
  }

  handleBack = () => {
    window.location.href="/cetak";
  }

  handleTest = () => {
    window.location.href="/cetaktest";
  }

  handleWawancara = () => {
    window.location.href="/cetakwawancara";
  }

  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Cetak Detail</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            {this.state.flow_id===4?
                              <Button onClick={this.handleTest} block style={{margin:5, height:50}} >Cetak Kartu Test</Button>
                            : ''
                            }

                            {this.state.flow_id===5?
                              <Button onClick={this.handleWawancara} block style={{margin:5, height:50}} >Cetak Jadwal Wawancara</Button>
                            : ''
                            }
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;